import React, { useState } from 'react';
import { CCard, CCardBody, CCardHeader, CFormInput, CCol, CRow, CButton, CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody } from '@coreui/react';

import { DataGrid } from '@mui/x-data-grid';
import {Link, useLoaderData} from 'react-router-dom';
import axios from "axios";
import AxiosInstance from 'src/components/Axios';
import CIcon from '@coreui/icons-react';
import { cilPen, cilPlus, cilTrash, cilClone, cilPrint } from '@coreui/icons';
import { confirmAlert } from 'react-confirm-alert';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyToastify from 'src/components/myComponents/MyToactify';
import PermissionsNoPage, {Permissions} from "src/components/Permissions";

const page = 1;
const postPerPage = 0;
const pageSize = 20;

const urlApi = '/roles';

export async function LoaderRoles({ params }) {
  let res = await  AxiosInstance.get(`${urlApi}?page=${page}`).then((response) => {
    return response;
  });
  return res;
}





const Roles = ({user_data}) => {
  if(!Permissions(user_data, 'Roles', 'VIEW_ROLES')){
      return <PermissionsNoPage />;
  }  
  const {data}  = useLoaderData();
  const [rows, setRows] = useState( data.member  );
  const postPerPage = page * data['hydra:totalItems'];

  const getRowsTable = () => {
    AxiosInstance.get(`${urlApi}?page=${page}`).then((response) => {
      if(response.status == 200)
        setRows( response.data.member  );
    });
  }
  const popup = MyToastify();

  function Deleted(row) {
    // console.log('row', row);
    confirmAlert({
      title: 'Ви впевнені що хочите видалити цей пункт ?',
      // message: 'Are you sure to do this.',
      message: (
        <>
         
        </>
      ),
      buttons: [
        {
          label: 'Так',
          onClick: () => {
            const response = AxiosInstance.delete(urlApi + '/' + row.id).then((response) => {
              getRowsTable();
              // Вивести popup про успішне видалення замовлення
              popup.toastifySuccess('Видалено');
            });
          }
        },
        {
          label: 'Ні',
          onClick: () => {
            return;
          }
        }
      ]
    });
  };
  
  
  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'name', headerName: 'Назва', width: 200 },
    { field: 'newsShot', headerName: 'Опис', width: 400 },
    {
      field: 'created_at',
      headerName: 'Дата',
      type: 'date',
      width: 160,
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      renderCell: (params) => {
        return <Link to={'/roles/update/' + params.row.id}>Редагувати</Link>;
      }
    },
    {
      field: "delete",
      headerName: "Видалити",
      sortable: false,
      renderCell: (params) => {
        return <CButton color="danger" variant="outline" title="Видалити замовлення"
          onClick={() => {
            Deleted(params.row)
          }
          }><CIcon icon={cilTrash} customClassName="nav-icon" height={20} /></CButton>;
      }
    },
  ];



    return (
      <CRow>
        <ToastContainer />

        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader>
              <strong>Ролі</strong>
            </CCardHeader>
            <CCardBody>
              <p className="text-medium-emphasis small">
                <Link to="/roles/create">Створити</Link>
              </p>
              <div style={{ height: 600, width: '100%' }}>

              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={pageSize}
                rowsPerPageOptions={[postPerPage]}
                // checkboxSelection
              />
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    );
};

export default Roles;
