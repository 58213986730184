import React, { useEffect, useRef, useState } from 'react';
import {
  CForm,
  CFormLabel,
  CFormInput,
  CFormTextarea,
  CButton, CRow, CCol, CCard, CCardHeader, CCardBody
} from '@coreui/react';
import { Link, useLoaderData, useNavigate } from 'react-router-dom';
import axios from "axios";
import Select from 'react-select'
import { DataGrid } from '@mui/x-data-grid';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {
  cilPen,
  cilPlus,
  cilTrash
} from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import AxiosInstance from 'src/components/Axios';
// import MenuItemForm from 'src/components/menu/MenuItemForm';
import BasicModal from 'src/components/Modal';
import Accordion from 'react-bootstrap/Accordion';

import { BsPlusLg, BsSortNumericDown, BsFillTrashFill } from 'react-icons/bs';
import { AiOutlineArrowDown, AiFillEdit } from 'react-icons/ai';
import Button from 'react-bootstrap/Button';
import '../../App.css';
import { transliterate } from 'transliteration';
import SlideForm from 'src/components/slider/SlideForm';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyToastify from 'src/components/myComponents/MyToactify';
import Card from 'react-bootstrap/Card'
import { sortObj } from 'src/components/store/sortObj';
import PermissionsNoPage, { Permissions } from "src/components/Permissions";
import LangugesPanel from './LangugesPanel';




export async function LoaderSliderUpdate({ params }) {
  if (params.menuId != undefined) {
    let res = await AxiosInstance.get('/sliders/' + params.menuId).then((response) => {
      return response;
    });

    return res;
  }
  return false;
}

const initialValues = {
  // id: "",
  name: "",
  slug: "",
  content: "",
};
const create = true;

const SliderForm = ({ user_data }) => {
  if (!Permissions(user_data, 'Slider', 'EDIT_SLIDER')) {
    return <PermissionsNoPage />;
  }
  const navigate = useNavigate();
  const { data } = useLoaderData();
  const [items, setItems] = useState([]);
  const [language, setLaguage] = useState((data != undefined) ? data.language?.key : 'ua');
  const popup = MyToastify();
  // console.log(data);
  /** Дані форми */
  const [values, setValues] = useState(initialValues);
  useEffect(() => {
    if (data != undefined) {

      getItems();
      EditMenu(data);
    }
  }, [data])

  function getItems() {
    AxiosInstance.get('/slides?slider.id=' + data.id).then((response) => {
      setItems(response.data.member );
    });
  }

  function EditMenu(row) {
    setValues({
      ...values,
      // id: row.id,
      name: row.name,
      slug: row.slug,
      content: row.content,
    });
  }


  const handleInputChange = (e) => {
    const { name, value, type } = e.target;

    setValues({
      ...values,
      [name]: type === "number" ? parseInt(value, 10) : value,
    });
  };


  /** Відправка форми */
  const handleSubmit = event => {
    event.preventDefault();

    const filtredValues = { ...values, slug: transliterate(values.name).toLowerCase().replace(/ /g, '_') };
    setValues(filtredValues);

    if (data == undefined) {
      filtredValues.language = `/api/languages/1`;
      const response = AxiosInstance.post('/sliders', filtredValues).then(response => {
        console.log(response)
        if (response.status == 201) {
          // navigate(`/menu/update/${response.data.id}`);
          popup.toastifySuccess('Слайдер створено')
          navigate('/slider')
        }
      }).catch(error => {
        popup.toastifyWarning('Слайдер не створено')
        navigate('/slider')
      })


    } else {
      filtredValues.language = data.language ? `/api/languages/${data.language.id}` : `/api/languages/1`;
      const response = AxiosInstance.put('/sliders/' + data.id, filtredValues).then((response) => {

        if (response.status == 200) {
          // navigate(`/menu/update/${response.data.id}`);
          popup.toastifySuccess('Слайдер оновлено')
          navigate('/slider')
        }
      }).catch(error => {
        popup.toastifyWarning('Слайдер не оновлено')
        navigate('/slider')
      })
    }
    // const response = AxiosInstance.put('/menus/' + data.id, values);

    // }
  }

  const [showModal, setShowModal] = useState(false);

  const actionShowModal = (rez, isUpdate = false) => {
    setShowModal(rez);
    if (!rez && isUpdate)
      getItems();
  };
  // console.log('values', values)
  // console.log('items', items)
  return (
    <>
      <ToastContainer />
      <div className='col d-flex justify-content-end'>
        {data !== undefined &&
          <LangugesPanel data={data}
            // setMyData={setMyData}
            setLaguage={setLaguage}
            language={language} />}
      </div>
      <h1></h1>

      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader>
              <strong>{(data != undefined) ? values.name : 'Створити'}</strong> <small>*</small>
            </CCardHeader>
            <CCardBody>
              <CForm onSubmit={handleSubmit}>

                <div className="mb-3">
                  <CFormLabel htmlFor="exampleFormControlInput1">Назва</CFormLabel>
                  <CFormInput type="hidden" value={values.id} autoComplete="off" />
                  <CFormInput type="text" name="name" value={values.name} onChange={handleInputChange} autoComplete="off" />
                </div>
                <div className="mb-3">
                  <CFormLabel htmlFor="exampleFormControlInput1">Slug</CFormLabel>
                  <CFormInput type="text" name="type" value={values.slug} onChange={handleInputChange} autoComplete="off" disabled={true} />
                </div>
                <div className="mb-3">
                  <CFormLabel htmlFor="exampleFormControlInput1">Контент</CFormLabel>
                  <CFormTextarea type="text" name="content" value={values.content} onChange={handleInputChange} rows={6} />
                </div>
                <CButton type="submit" color="success">Submit</CButton>
              </CForm>
            </CCardBody>
          </CCard>
        </CCol>

      </CRow>

      {(data != undefined) &&
        <CRow>
          <CCol xs={12}>
            <CCard className="mb-4">
              <CCardHeader>
                <strong>Слайди</strong> <small>*</small>
              </CCardHeader>
              <CCardBody>

                <div className='p-5'>
                  <MyAccordion items={items} getItems={getItems} />
                </div>
                <BasicModal
                  show={showModal}
                  actionShowModal={actionShowModal}
                  title={`Додати пукт меню до - ${data.name}`}
                  btn_name={<><BsPlusLg /> Додати слайд</>}
                  content={
                    <SlideForm toastifySuccess={popup.toastifySuccess} parent_id={data.id} actionShowModal={actionShowModal} key="0" />
                  }
                />
              </CCardBody>

            </CCard>
          </CCol>
        </CRow>
      }
    </>
  );
};

const MyAccordion = ({ items, getItems, key }) => {
  return (
    <div key={key}>
      {sortObj(items, 'orders').map((d, index) => {
        return <SlideList key={index} data={d} getItems={getItems} />
      })
      }
    </div>
  );
}
function Deleted(row, getItems) {
  confirmAlert({
    title: 'Увага!',
    message: `Ви впевнені що хочите видалити цей "${row.name}" пункт ?`,
    buttons: [
      {
        label: 'Так',
        onClick: () => {
          const response = AxiosInstance.delete(`/slides/${row.id}`).then((response) => {
            getItems();
          });
        }
      },
      {
        label: 'Ні',
        onClick: () => {
          return;
        }
      }
    ]
  });
}

const SlideList = ({ data, getItems }) => {
  const [showModalItem, setShowModalItem] = useState(false);
  const popup = MyToastify();

  const actionShowModalItem = (rez, isUpdate = false) => {
    setShowModalItem(rez);
    if (!rez && isUpdate)
      getItems();
  };

  return (
    <>
      <Card key={data.id}>
        <Card.Header>
          <div className='row'>
            <div className='col'>
              Слайд
            </div>
            <div className='col d-flex justify-content-end'>
              <small className='SortPunkt'><BsSortNumericDown /> - {data.orders}</small>
            </div>
          </div>
        </Card.Header>
        <Card.Body>



          <div className='row'>
            <div className='col-4'>
              {(data.media != undefined) ? <img src={process.env.REACT_APP_SERVER_URL + data.media.contentUrl} /> : ''}
            </div>
            <div className='col-6'>
              <h4>{data.name}</h4>
              <div dangerouslySetInnerHTML={{ __html: data.content }}></div>
            </div>
            <div className='col-2'>

              <BasicModal
                show={showModalItem}
                actionShowModal={actionShowModalItem}
                title={`Редагувати пукт меню  - ${data.name}`}
                btn_name={<><AiFillEdit /></>}
                content={
                  <SlideForm toastifySuccess={popup.toastifySuccess} parent_id={data.id} data={data} actionShowModal={actionShowModalItem} />
                }
              />

              <Button variant="danger" onClick={() => Deleted(data, getItems)} className='mt-5'><BsFillTrashFill /></Button>

            </div>
          </div>



          {/* <hr />
          <h5><AiOutlineArrowDown />Пункти під меню "{data.name}": </h5>

          {(data.children != undefined && data.children.length > 0) && <MyAccordion items={data.children} getItems={getItems} key={data.id} />} */}
        </Card.Body>
      </Card>
    </>
  )
}
export default SliderForm;
