import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import AxiosInstance from 'src/components/Axios';

// Інформація до товару для сторінки інформації товару )))
const ProductInformationInfo = (props) => {
    // console.log('props', props);

    const urlApi = '/product_infos';
    // Інформація, яка додана до продукту
    const [productInfo, setProductInfo] = useState([]);

    // Запит за категоріями, які додані до товару
    useEffect(() => {
        if (props.productId !== undefined) {
            AxiosInstance.get(`${urlApi}?product.id=${props.productId}`).then((response) => {
                if (response.status == 200) {
                    setProductInfo(response.data.member );
                }
            });
        }
    }, [props])

    return (
        <div className='mt-4'>
            <h2>Інформація про товар</h2>

            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th className='products-table__th1'>#</th>
                        <th className='products-table__th2'>Назва</th>
                        <th>Значення</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        (productInfo.length > 0) ?
                            productInfo.map((row, index) => {
                                return (
                                    <tr key={row.id}>
                                        <td className='products-table__td1'>{index + 1}</td>
                                        <td className='products-table__td2'>{row.name}</td>
                                        <td>{row.value}</td>
                                    </tr>
                                )
                            })
                            :
                            <tr>
                                <td colSpan={3}>Інформація відсутня</td>
                            </tr>
                    }
                </tbody>
            </Table>
        </div>
    );
};

export default ProductInformationInfo;