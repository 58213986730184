import React, { useEffect, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import {
  CForm,
  CFormLabel,
  CFormInput,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CFormSelect,
} from '@coreui/react';
import Select from 'react-select'
import { Link, useLoaderData, useNavigate } from 'react-router-dom';
import axios from "axios";
import { formatUTC } from 'src/store';
import dayjs from 'dayjs';
import AddHeaderSlider from 'src/components/headers/AddHeaderSlider';
import AxiosInstance from 'src/components/Axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import MyToastify from 'src/components/myComponents/MyToactify';
import PageInfoList from 'src/components/page-info/PageInfoList';
import ProductCategories from 'src/components/product/ProductCategories';
import MyToastify from 'src/components/myComponents/MyToactify';
import MediaFrom from '../../components/media/MediaFrom';
import AddCategories from 'src/components/category/AddCategories';
import LangugesPanel from './LangugesPanel';
import RelatedPublications from 'src/components/related/RelatedPublications';

const tokenStr = localStorage.getItem('user');
const header = { headers: { "Authorization": `Bearer ${tokenStr}` } };

export async function LoaderProjectsUpdate({ params }) {
  if (Object.keys(params).length != 0) {
    let res = await axios.get(process.env.REACT_APP_SERVER_URL + '/api/projects/' + params.prodId, {
      params: {
        "g[0]": "projects:read",
        "g[1]": "projects:financed",
        "g[2]": "projects:partners",
        "g[3]": "projects:coordinator"
      }
    }).then((response) => {
      return response;
    });
    return res;
  }
  return false;
}
const formReducer = (state, event) => {
  return {
    ...state,
    [event.target.name]: event.target.value
  }
}
const ProjectsForm = () => {
  const { data } = useLoaderData();
  const [name, setName] = useState((data != undefined) ? data.name : '');
  const [shortName, setShortName] = useState((data?.shortName != undefined) ? formatShortName(data.shortName) : '');
  const [shortDescription, setShortDescription] = useState((data != undefined) ? data.shortDescription : '');
  const [status, setStatus] = useState((data != undefined) ? data.status : '');
  const [budget, setBudget] = useState((data != undefined) ? data.budget : '');
  const [media, setMedia] = useState((data != undefined) && data.mainMedia?.id);
  const [mediaUrl, setMediaUrl] = useState(false);

  function formatShortName(name) {
    return name.replace(/<\/?h1[^>]*>/g, '');
  }

  // Викликає popup з власного компонента
  const popup = MyToastify();
  // const [financed, setFinanced] = useState((data != undefined) ? data.financed : []);
  // const [partners, setPartners] = useState((data != undefined) ? data.partners : []);

  const [dateStart, setDateStart] = useState((data != undefined) ? new Date(data.dateStart).toISOString() : new Date().toISOString());
  const [dateEnd, setDateEnd] = useState((data != undefined) ? new Date(data.dateEnd).toISOString() : new Date().toISOString());
  const [partners, setPartners] = useState((data?.partners != undefined) ? data.partners.map(partner => { return { label: partner.name, value: partner.id } }) : []);
  const [coordinator, setCoordinator] = useState((data?.coordinator != undefined) ? data.coordinator.map(coordinator => { return { label: coordinator.name, value: coordinator.id } }) : []);
  const [financed, setFinanced] = useState((data?.financed != undefined) ? data.financed.map(finance => { return { label: finance.name, value: finance.id } }) : []);
  const [fontSize, setFontSize] = useState('36');
  const [unitOfMeasurement, setUnitOfMeasurment] = useState('px')
  const [currency, setCurrency] = useState((data?.curency != undefined) ? data.curency : "$")

  const [sliders, setSliders] = useState((data != undefined && data.slider != undefined) ? data.slider.match(/(\d+)/)?.[0] : false);
  const [accountOptions, setAccountOptions] = useState([]);
  // const [partnersOptions, setPartnersOptions] = useState([]);
  // const [financeOptions, setFinanceOptions] = useState([]);
  const [language, setLaguage] = useState((data != undefined) ? data.language?.key : 'ua');

  const editorRef = useRef(null);
  const navigate = useNavigate();
  // Ключ для Editor
  const apiKey = process.env.REACT_APP_TINYMCE_API_KEY;

  //значеня для селекту одиниць виміру
  const unitsOfMeasurement = [
    { value: 'px', label: 'px' },
    { value: 'rem', label: 'rem' },
    { value: 'em', label: 'em' },
  ];
  //значення для селекту вибору валюти
  const unitsOfCurrency = [
    { value: '$', label: 'долар' },
    { value: '€', label: 'євро' },
    { value: '₴', label: 'гривня' },
  ];

  const handleChangeUnitsOfMeasurment = (selectedOption) => {
    // console.log('Selected unit:', selectedOption.value);
    setUnitOfMeasurment(selectedOption.value);
  };

  const handleChangeCurrency = (selectedOption) => {
    // console.log('Selected unit:', selectedOption.value);
    setCurrency(selectedOption.value);
  };
  useEffect(() => {
    if (data) {
      setName(data.name || '');
      setShortName(data.shortName ? formatShortName(data.shortName) : '');
      setShortDescription(data.shortDescription || '');
      setStatus(data.status || '');
      setBudget(data.budget || '');
      setMedia(data.mainMedia?.id || undefined);
      setDateStart(data.dateStart ? new Date(data.dateStart).toISOString() : new Date().toISOString());
      setDateEnd(data.dateEnd ? new Date(data.dateEnd).toISOString() : new Date().toISOString());
      setPartners(
        data.partners
          ? data.partners.map((partner) => ({ label: partner.name, value: partner.id }))
          : []
      );
      setFinanced(
        data.financed
          ? data.financed.map((finance) => ({ label: finance.name, value: finance.id }))
          : []
      );
      setCoordinator(
        data.coordinator
          ? data.coordinator.map((coordinator) => ({ label: coordinator.name, value: coordinator.id }))
          : []
      );
      setCurrency(data.curency || '$');
      setSliders(
        data.slider ? data.slider.
          // split('/').shift() : false
          match(/(\d+)/)?.[0] : false
      );
      // setLaguage(data.language?.key || 'ua');
    }
  }, [data]);

  useEffect(() => {
    // console.log(media);
    if (media) {
      AxiosInstance.get(`/media_objects/${media}`).then((response) => {
        if (response.status == 200) {
          setMediaUrl(response.data.contentUrl)
        }
      });
    }
  }, [media]);

  useEffect(() => {
    AxiosInstance.get('/accounts?pagination=false').then((response) => {
      const data = response.data.member ;
      const accountOptions = data.map(account => {
        return { label: account.name, value: account.id }
      })
      setAccountOptions(accountOptions);

    }).catch((err) => console.log(err));
  }, [])

  useEffect(() => {
    if (data?.shortName !== undefined) {
      const regex = /font-size:([\d\.]+)([a-zA-Z%]+)/;
      const match = data.shortName.match(regex);
      if (match) {
        setFontSize(match[1]);
        setUnitOfMeasurment(match[2]);
      }
    }
  }, [data?.shortName]);

  const handleSubmit = event => {
    event.preventDefault();
    if (editorRef.current) {

      const article = {
        name: name,
        shortName: `<h1 style='font-size:${fontSize}${unitOfMeasurement}'>${shortName}</h1>`,
        dateStart: dateStart,
        dateEnd: dateEnd,
        status: status,
        shortDescription: shortDescription,
        partners: partners.map(partner => `/api/accounts/${partner.value}`),
        financed: financed.map(finance => `/api/accounts/${finance.value}`),
        coordinator: coordinator.map(coordinator => `/api/accounts/${coordinator.value}`),
        // financed: financed,
        mainMedia: (media && media !== undefined) ? `/api/media_objects/${media}` : null,
        budget: parseInt(budget) || 0,
        content: editorRef.current.getContent(),
        curency: currency,
        // slider: data.slider ? data.slider : false
        ...(data?.slider ? { slider: data.slider } : {}),
      };
      // console.log(article);
      if (data != undefined) {
        article.language = data.language ? `/api/languages/${data.language.id}` : `/api/languages/1`;
        const response = axios.put(process.env.REACT_APP_SERVER_URL + '/api/projects/' + data.id, article, header).then(() => { navigate('/projects'); });
      } else {
        article.language = `/api/languages/1`;
        const response = axios.post(process.env.REACT_APP_SERVER_URL + '/api/projects', article, header).then(() => { navigate('/projects'); });
      }
    }
  }

  return (
    <>
      <ToastContainer />
      <div className='col d-flex justify-content-end'>
        {data !== undefined &&
          <LangugesPanel data={data}
            // setMyData={setMyData}
            setLaguage={setLaguage}
            language={language} />}
      </div>
      {(mediaUrl && mediaUrl != undefined) ? <img style={{ objectFit: 'cover' }} className="mb-3" width={300} height={300} src={process.env.REACT_APP_SERVER_URL + mediaUrl} /> : ''}
      <div className="mb-3">
        <MediaFrom type="category" setNewMediaId={setMedia} toastifySuccess={popup.toastifySuccess} />
      </div>
      <CForm onSubmit={handleSubmit}>
        {data !== undefined && <AddHeaderSlider id={(sliders !== false) ? sliders : undefined} newSlider={setSliders} parentUrl={`projects/${data.id}`} />}
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Назва</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput1" value={name} onChange={(e) => setName(e.target.value)} autoComplete="off" />
        </div>
        {/* //Додавання інпута і селекта */}
        <div className='row'>
          <div className='col'>
            <div className="mb-3">
              <CFormLabel htmlFor="exampleFormControlInput1">Коротка назва</CFormLabel>
              <CFormInput type="text" id="exampleFormControlInput1" value={shortName} onChange={(e) => setShortName(e.target.value)} autoComplete="off" />
            </div>
          </div>
          <div className='col'>
            <div className="mb-3">
              <CFormLabel htmlFor="exampleFormControlInput1">Розмір шрифта</CFormLabel>
              <CFormInput type="text" id="exampleFormControlInput1" value={fontSize} onChange={(e) => setFontSize(e.target.value)} autoComplete="off" />
            </div>
          </div>
          <div className='col'>
            <div className="mb-3"
            >
              <CFormLabel htmlFor="exampleFormControlInput1">Одиниці виміру</CFormLabel>
              <Select
                // options={props.defaultStorageElement}
                options={unitsOfMeasurement}
                // value={props.storageElement}
                value={unitsOfMeasurement.find((unit) => unit.value === unitOfMeasurement)}
                // onChange={(value) => { props.handleChange(value) }}
                onChange={(value) => { handleChangeUnitsOfMeasurment(value) }}
              />
            </div>
          </div>
          {/* //Додавання інпута і селекта */}
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Короткий опис</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput1" value={shortDescription} onChange={(e) => setShortDescription(e.target.value)} autoComplete="off" />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput2">Партнери</CFormLabel>
          <Select
            value={partners}
            isMulti
            name="colors"
            options={accountOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(value) => {
              setPartners(value);
            }}
          />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput2">Фінансування</CFormLabel>
          <Select
            value={financed}
            isMulti
            name="colors"
            options={accountOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(value) => {
              setFinanced(value);
            }}
          />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput2">Координатор</CFormLabel>
          <Select
            value={coordinator}
            isMulti
            name="colors"
            options={accountOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(value) => {
              setCoordinator(value);
            }}
          />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Статус</CFormLabel>
          <CFormSelect
            aria-label="Default select example"
            onChange={(e) => setStatus(e.target.value)}
            defaultValue={status}
            required
            options={[
              { label: 'Статус проекту', value: '' },
              { label: 'Completed', value: 'Completed' },
              { label: 'Planned', value: 'Planned' },
              { label: 'Pending', value: 'Pending' }
            ]}
          />
        </div>

        <div className='row'>
          <div className='col'>
            <div className="mb-3">
              <CFormLabel htmlFor="exampleFormControlInput1">Бюджет</CFormLabel>
              <CFormInput type="number" id="exampleFormControlInput1" value={budget} onChange={(e) => setBudget(e.target.value)} autoComplete="off" />
            </div>
          </div>
          <div className='col'>
            <div className="mb-3"
            >
              <CFormLabel htmlFor="exampleFormControlInput1">Вибір валюти</CFormLabel>
              <Select
                options={unitsOfCurrency}
                value={unitsOfCurrency.find((unit) => unit.value === currency)}
                onChange={(value) => { handleChangeCurrency(value) }}
              />
            </div>
          </div>

        </div>

        {/* <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Фінансування</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput1" value={financed} onChange={(e) => setFinanced(e.target.value.split(','))} autoComplete="off" />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Партненри</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput1" value={partners} onChange={(e) => setPartners(e.target.value.split(','))} autoComplete="off" />
        </div> */}

        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Дата створення </CFormLabel>
          <CFormInput type="date" id="exampleFormControlInput1" value={dayjs(dateStart).format('YYYY-MM-DD')} onChange={(e) => setDateStart(e.target.value)} />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Дата завершення </CFormLabel>
          <CFormInput type="date" id="exampleFormControlInput1" value={dayjs(dateEnd).format('YYYY-MM-DD')} onChange={(e) => setDateEnd(e.target.value)} />
        </div>
        <RelatedPublications
          editorRef = {editorRef}
          popup = {popup}
        />
        <div className="mb-3">
          <Editor
            apiKey={apiKey}
            onInit={(evt, editor) => editorRef.current = editor}
            initialValue={(data != undefined) ? data.content : ''}
            init={{
              height: 700,
              menubar: 'edit view insert format',
              plugins: [
                'advlist', 'autolink',
                'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks', 'fullscreen',
                'insertdatetime', 'media', 'table', 'help', 'code'
              ],
              toolbar: 'undo redo | fontfamily fontsize | fontselect | bold italic backcolor | ' +
                'alignleft aligncenter alignright alignjustify | ' +
                'bullist numlist checklist outdent indent | removeformat | link media a11ycheck code table help',
              content_style: 'body { font-family:FixelDisplayRegular; font-size:14px }',
              font_family_formats: "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Fixel Display Regular=fixelDisplayRegular; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
              content_css: '/custom.css'
            }}
          />
        </div>
        <CButton type="submit" color="success">Зберегти</CButton>
      </CForm>

      {(data != undefined) && <>
        <div className="mb-3">
          <PageInfoList id={data.id} type="project" />
        </div>
        <AddCategories type="projects" data_id={data.id} toastifySuccess={popup.toastifySuccess} />
      </>
      }
    </>
  );
};

export default ProjectsForm;
