import React from 'react';
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CButton
} from '@coreui/react';
import { DataGrid } from '@mui/x-data-grid';
import {Link, useLoaderData} from 'react-router-dom';
import axios from "axios";
import CIcon from '@coreui/icons-react'
import {
    cilPen,
    cilPlus,
    cilTrash
  } from '@coreui/icons'
import { confirmAlert } from 'react-confirm-alert'; // Import
import { useState } from 'react';
import PermissionsNoPage, {Permissions} from "src/components/Permissions";

const tokenStr = localStorage.getItem('user');
const header = { headers: {"Authorization" : `Bearer ${tokenStr}`} };



export async function LoaderLanguages({ params }) {
    let res = await  axios.get(process.env.REACT_APP_SERVER_URL + '/api/languages', header).then((response) => {
      return response;
    });
    return res;
}

const Languages = ({user_data}) => {
    if(!Permissions(user_data, 'Languages', 'VIEW_LANGUAGES')){
        return <PermissionsNoPage />;
    }
    const {data}  = useLoaderData();
    const [rows, setRows] = useState(false);
    const [rowCountState, setRowCountState] = React.useState(0);
    if(!rows){
      setRowCountState(data['hydra:totalItems'])
      setRows(data.member );
    }

    const getRowsTable = (p=1) => {
        axios.get(process.env.REACT_APP_SERVER_URL + '/api/languages?page=' + p, header).then((response) => {
            console.log(response);
            setRows(response.data.member );
          });
    };


    const handlePageChange = (page) => {
      getRowsTable(page + 1);
    }



    function Deleted(row) {
        confirmAlert({
          title: 'Ви впевнені що хочите видалити цей пункт ?',
          message: 'Are you sure to do this.',
          buttons: [
            {
              label: 'Так',
              onClick: () => {
                const response = axios.delete(process.env.REACT_APP_SERVER_URL + '/api/languages/' + row.id, header).then((response) => {
                  getRowsTable();
                });
              }
            },
            {
              label: 'Ні',
              onClick: () => {
                return;
              }
            }
          ]
        });
    
      }
    
    const columns = [
    { field: 'name', headerName: 'Назва', width: 200 },
    { field: 'option_key', headerName: 'Ключ', width: 220 },
    {
        field: 'value',
        headerName: 'Значення',
        width: 400
    },
    {
        field: "action",
        headerName: "Редагувати",
        sortable: false,
        width: 100,
        renderCell: (params) => {
        return <Link to={'/languages/update/' + params.row.id}><CButton color="dark" variant="outline"><CIcon icon={cilPen} customClassName="nav-icon" height={20} /></CButton></Link>;
        }
    },
    {
        field: "delete",
        headerName: "Видалити",
        sortable: false,
        width: 100,
        renderCell: (params) => {
        return <CButton color="danger" variant="outline" onClick={() => {
            Deleted(params.row)
          }
        }><CIcon icon={cilTrash} customClassName="nav-icon" height={20} /></CButton>;
        }
    },
    ];
      return (
        <CRow>
          <CCol xs={12}>
            <CCard className="mb-4">
              <CCardHeader>
                <strong>Мови</strong> <small>для перекладів</small>
              </CCardHeader>
              <CCardBody>
                <p className="text-medium-emphasis small">
                  <Link to="/languages/create"><CButton color="secondary"><CIcon icon={cilPlus} customClassName="nav-icon" height="15" />Створити</CButton></Link>
                </p>
                <div style={{ height: 800, width: '100%' }}>
  
                <DataGrid
                   rows={rows}
                   columns={columns}
                   pageSize={30}
                   rowsPerPageOptions={[30]}
                   pagination
                   rowCount={rowCountState}
                   paginationMode="server"
                   onPageChange={handlePageChange}
                />
                </div>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      );


}

export default Languages;