import React, { useState } from 'react';
import { CCard, CCardBody, CCardHeader, CCol, CRow, CButton } from '@coreui/react';
import { DataGrid } from '@mui/x-data-grid';
import { Link, useLoaderData } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import { cilPen, cilPlus, cilTrash } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import MyToastify from 'src/components/myComponents/MyToactify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';

const page = 1;
const pageSize = 20;
const urlApi = '/locations';
const urlRouter = '/locations';

export async function LoaderLocations({ params }) {
  let res = await AxiosInstance.get(`${urlApi}?page=${page}`).then((response) => {
    return response;
  });
  return res;
};

const Locations = () => {
  const { data } = useLoaderData();
  const [rows, setRows] = useState(false);
  const [rowCountState, setRowCountState] = React.useState(0);

  // Викликає popup з власного компонента
  const popup = MyToastify();
  // Текст для popup
  const popupTextDeleteSuccess = "Підрозділ видалено";

  if (!rows) {
    setRowCountState(data['hydra:totalItems'])
    setRows(data.member );
  };

  const getRowsTable = (p = 1) => {
    AxiosInstance.get(`${urlApi}?page=${p}`).then((response) => {
      setRows(response.data.member );
    });
  };

  const handlePageChange = (page) => {
    getRowsTable(page + 1);
  };

  function Deleted(row) {
    confirmAlert({
      title: 'Ви впевнені що хочите видалити цей пункт?',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Так',
          onClick: () => {
            const response = AxiosInstance.delete(`${urlApi}/${row.id}`).then((response) => {
              getRowsTable();
              // Вивести popup про успішне видалення замовлення
              popup.toastifySuccess(popupTextDeleteSuccess);
            });
          }
        },
        {
          label: 'Ні',
          onClick: () => {
            return;
          }
        }
      ]
    });
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 30 },
    {
      field: 'name', headerName: 'Назва', width: 200,
      renderCell: (params) => {
        return <Link to={`${urlRouter}/update/${params.row.id}`} style={{ textDecoration: "none", color: "rgba(0, 0, 0, 0.87)" }}>{params.row.name}</Link>;
      }
    },

    { field: 'description', headerName: 'Опис', width: 400 },
    // { field: 'parent', headerName: 'parent', width: 200 },

    // {
    //   field: 'created_at',
    //   headerName: 'Дата',
    //   type: 'date',
    //   width: 160,
    // },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      width: 65,
      renderCell: (params) => {
        return <Link to={`${urlRouter}/update/${params.row.id}`}><CButton color="dark" variant="outline" title="Редагувати підрозділ"><CIcon icon={cilPen} customClassName="nav-icon" height={20} /></CButton></Link>;
      }
    },
    {
      field: "delete",
      headerName: "Видалити",
      sortable: false,
      width: 85,
      renderCell: (params) => {
        return <CButton color="danger" variant="outline" onClick={() => { Deleted(params.row) }} title="Видалити підрозділ"
        > <CIcon icon={cilTrash} customClassName="nav-icon" height={20} /></CButton >;
      }
    },
  ];

  return (
    <>
      {/* Popup про успішні зміни (справа зверху) */}
      <ToastContainer />

      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader>
              <strong>Підрозділи</strong> <small></small>
            </CCardHeader>
            <CCardBody>
              <p className="text-medium-emphasis small">
                <Link to={`${urlRouter}/create`}><CButton color="secondary"><CIcon icon={cilPlus} customClassName="nav-icon" height={15} />Створити</CButton></Link>
              </p>
              <div style={{ height: 600, width: '100%' }}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  pageSize={30}
                  rowsPerPageOptions={[30]}
                  pagination
                  rowCount={rowCountState}
                  paginationMode="server"
                  onPageChange={handlePageChange}
                />
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default Locations;