import React, { useEffect, useRef, useState } from 'react';
import {
  CForm,
  CFormLabel,
  CFormInput,
  CButton, CRow, CCol, CCard, CCardHeader, CCardBody,
  CFormTextarea
} from '@coreui/react';
import { Link, useLoaderData, useNavigate } from 'react-router-dom';
import axios from "axios";
import Select from 'react-select'
import { DataGrid } from '@mui/x-data-grid';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {
  cilPen,
  cilPlus,
  cilTrash
} from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import AxiosInstance from 'src/components/Axios';
import BasicModal from 'src/components/Modal';
import MediaFrom from '../media/MediaFrom';

const initialValues = {
  // id: "",
  name: "",
  content: "",
  media: "",
  data: [],
  // slider: "",
  btnUrl: "",
  mediaUrl: "",
  orders: 0,
};

const SlideForm = ({ id, parent_id, actionShowModal, data, toastifySuccess }) => {

  const [values, setValues] = useState(initialValues);
  const [media, setMedia] = useState();
  const [mobileMedia, setMobileMedia] = useState();
  const [mediaUrl, setMediaUrl] = useState(false);

  useEffect(() => {
    if (data != undefined) {
      console.log(data.orders);
      setValues({
        name: data.name,
        content: data.content,
        media: data.media,
        data: data.data,
        // slider: data.slider,
        btnUrl: data.btnUrl,
        mediaUrl: data.mediaUrl,
        orders: data.orders,
      });
      if (data.media != undefined)
        setMedia(data.media.id)
    }

  }, [data])

  useEffect(() => {
    // console.log(media);
    if (media != undefined) {
      setValues({
        ...values,
        media: `/api/media_objects/${media}`
      });

      const response = AxiosInstance.get(`/media_objects/${media}`).then((response) => {
        if (response.status == 200) {
          setMediaUrl(response.data.contentUrl)
        }
      });
    }
  }, [media]);
  const handleInputChange = (e) => {
    const { name, value, type } = e.target;

    setValues({
      ...values,
      [name]: type === "number" ? parseInt(value, 10) : value
    });
  };
  /** Відправка форми */
  const handleSubmit = event => {
    event.preventDefault();

    if (data == undefined) {
      // parent_id
      values['slider'] = `/api/sliders/${parent_id}`;
      if (values.media == '')
        delete values.media;

      const response = AxiosInstance.post('/slides', values).then((response) => {
        if (response.status == 201) {
          // actionShowModal(setShowModal(!rez), getItems()); перший параметр (закрити модальне вікно), другий - оновити дані)
          actionShowModal(false, true);
          // navigate(`/menu/update/${response.data.id}`);
        }
      });
    } else {
      const response = AxiosInstance.put('/slides/' + data.id, values).then((response) => {
        if (response.status == 200) {
          // actionShowModal(setShowModal(!rez), getItems()); перший параметр (закрити модальне вікно), другий - оновити дані)
          actionShowModal(false, true);
        }
        // if(response.status == 201){
        //   navigate(`/menu/update/${response.data.id}`);
        // }
      });
    }
    // const response = AxiosInstance.put('/menus/' + data.id, values);

    // }

  }
  return (
    <>
      {(mediaUrl && mediaUrl != undefined) ? <img src={process.env.REACT_APP_SERVER_URL + mediaUrl} /> : ''}
      <MediaFrom type="category" setNewMediaId={setMedia} toastifySuccess={toastifySuccess} />

      {(mediaUrl && mediaUrl != undefined) ? <img src={process.env.REACT_APP_SERVER_URL + mediaUrl} /> : ''}
      <MediaFrom type="category" setNewMediaId={setMobileMedia} toastifySuccess={toastifySuccess} />
      <CForm onSubmit={handleSubmit}>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Назва</CFormLabel>
          <CFormInput type="text" name="name" value={values.name} onChange={handleInputChange} />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Контент</CFormLabel>
          <CFormTextarea name="content" value={values.content} onChange={handleInputChange} />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Посилання для  кнопки</CFormLabel>
          <CFormInput type="text" name="btnUrl" value={values.btnUrl} onChange={handleInputChange} />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Посилання для  медіа</CFormLabel>
          <CFormInput type="text" name="mediaUrl" value={values.mediaUrl} onChange={handleInputChange} />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Сортування</CFormLabel>
          <CFormInput type="number" name="orders" value={values.orders} onChange={handleInputChange} />
        </div>
        <CButton type="submit" color="success">Submit</CButton>
      </CForm>
    </>
  );
}

export default SlideForm;