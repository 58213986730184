import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import ProductTableItem from "./ProductTableItem";
import { useSelector } from "react-redux";
import AxiosInstance from "src/components/Axios";

// Таблиця з товарами для вибору для додавання до замовлення
const ChooseProductTable = (props) => {
    // console.log("props", props);
    const reduxBalance = useSelector(state => state.balance)
    // useEffect(() => {
    //     console.log(reduxBalance);
    // }, [reduxBalance]);

    const urlApi_1 = '/options';

    const [priceGroupArr, setPriceGroupArr] = useState(""); // Для "які ціни показувати"
    const optionKeyForPriceGroup = "show_price_group"; // Ключ з списком "які ціни показувати"

    // Які ціни показувати
    const [isShowGurt_3, setIsShowGurt_3] = useState(false);
    const [isShowGurt_2, setIsShowGurt_2] = useState(false);
    const [isShowGurt_1, setIsShowGurt_1] = useState(false);
    const [isShowVIP, setIsShowVIP] = useState(false);

    // Відслідковувати які ціни показувати
    useEffect(() => {
        // console.log('priceGroupArr', priceGroupArr);
        if (priceGroupArr.length > 0) {
            const groupArr = priceGroupArr.split(',').map(el => el.trim().toLowerCase());
            // console.log('groupArr', groupArr);

            setIsShowGurt_3(groupArr.includes("3"));
            setIsShowGurt_2(groupArr.includes("2"));
            setIsShowGurt_1(groupArr.includes("1"));
            setIsShowVIP(groupArr.includes("vip"));
        };
    }, [priceGroupArr]);

    // Функція для запиту за датою прайсу та які ціни показувати
    const getShowPriceGroup = async (key) => {
        try {
            const response = await AxiosInstance.get(`${urlApi_1}?option_key=${key}`);
            // console.log('response', response);
            setPriceGroupArr(response.data.member [0].value)
        } catch (error) {
            console.error("Помилка запиту за 'які ціни показувати':", error);
        };
    };

    // Зробити запит за "які ціни показувати"
    useEffect(() => {
        getShowPriceGroup(optionKeyForPriceGroup);
    }, []);


    return (
        <>
            {
                <Table striped bordered hover data-aos="fade-up"
                    className="orders-choose__table"
                >
                    <thead>
                        <tr>
                            <th className="orders-choose__th orders-choose__th-img">Img</th>
                            <th className="orders-choose__th w-50">
                                <div>
                                    <div>Назва</div>
                                    <div>Артикул</div>
                                    {/* Відображати тільки для мобільної версії */}
                                    <div className="orders-choose__mobile">
                                        {/* <div>code1c</div> */}
                                        <div>Штрих-код</div>
                                    </div>
                                </div>
                            </th>
                            {/* <th className="orders-choose__th orders-choose__PC">code1c</th> */}
                            {/* <th className="orders-choose__th orders-choose__PC">Штрих-код</th> */}
                            <th className="orders-choose__th orders-choose__th-count ">Залишки<br />К-сть</th>
                            <th className="orders-choose__th orders-choose__th-price">
                                <div className="orders-choose__TC">
                                    <span>Роздріб</span>
                                    {isShowGurt_3 && <span>Гурт 3</span>}
                                    {isShowGurt_2 && <span>Гурт 2</span>}
                                    {isShowGurt_1 && <span>Гурт 1</span>}
                                    {isShowVIP && <span>VIP</span>}
                                    {/* <span>Націнка</span> */}
                                    <span className="orders-choose__short-PC">Собівартість</span>
                                    <span className="orders-choose__short-mobile">Собіварт</span>
                                </div>
                            </th>
                            <th className="orders-choose__th orders-choose__th-action"></th>
                        </tr>
                    </thead>
                    <tbody className="order-table">
                        {
                            (props.rows != false) &&
                            props.rows.map((row) => {
                                return (
                                    <ProductTableItem
                                        key={row.id} product={row} setSelectedProductId={props.setSelectedProductId} addToOrder={props.addToOrder}
                                        products={props.products} reduxBalance={reduxBalance} getOrder={props.getOrder}
                                        isShowGurt_3={isShowGurt_3} isShowGurt_2={isShowGurt_2} isShowGurt_1={isShowGurt_1} isShowVIP={isShowVIP}
                                    />
                                )
                            })
                        }
                    </tbody>
                </Table>
            }
        </>
    )
};

export default ChooseProductTable;