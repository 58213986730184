import React, { useState, useEffect } from 'react';
import { CCard, CCardBody, CCardHeader, CFormInput, CCol, CRow, CButton, CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody, CFormLabel } from '@coreui/react';
// import '@coreui/coreui/dist/css/coreui.min.css';
import { DataGrid } from '@mui/x-data-grid';
import { Link, useLoaderData } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import { cilPen, cilPlus, cilTrash } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import FilterUser from 'src/components/filter/FilterUser';
import FilterProduct from 'src/components/filter/FilterProduct';
import FilterStorage from 'src/components/filter/FilterStorage';
import useOpenAccordion from 'src/components/store/useOpenAccordion';

const page = 1;
const postPerPage = 0;
const pageSize = 20;
const urlApi = '/product_balance_in_storages';
const urlRouter = '/storage-balance';

export async function LoaderStorageBalance({ params }) {
  if (params.pid != undefined) {
    let res = await AxiosInstance.get(`${urlApi}?page=${page}&product.id=${params.pid}`).then((response) => {
      return response;
    });
    return res;
  }
  let res = await AxiosInstance.get(`${urlApi}?page=${page}`).then((response) => {
    return response;
  });
  return res;
};


const StorageBalance = () => {

  const { data } = useLoaderData();
  const [rows, setRows] = useState(false);
  const [rowCountState, setRowCountState] = React.useState(0);
  const [userData, setUserData] = useState(false);
  const [productData, setProductData] = useState(false);
  const [storageData, setStorageData] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [clear, setClear] = useState(false);

  // Відслідковувати стартове значення стану акордиону в залежності від розширення екрану
  const activeAccordionItem = useOpenAccordion();
  const activeItem = activeAccordionItem.activeItem;

  if (!rows) {
    setRowCountState(data['hydra:totalItems']);
    setRows(data.member );
  };

  const getRowsTable = (p = 1) => {
    setClear(false);
    let query = '';
    if (userData.value != undefined)
      query += `&manager.id=${userData.value}`;
    if (productData.value != undefined)
      query += `&product.id=${productData.value}`;
    if (storageData.value != undefined)
      query += `&storage.id=${storageData.value}`;

    AxiosInstance.get(urlApi + '?page=' + p + query,).then((response) => {
      // console.log('response', response);
      setRows(response.data.member );
      setRowCountState(response.data['hydra:totalItems']);
    });
  };

  const handlePageChange = (page) => {
    getRowsTable(page + 1);
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    {
      field: 'name', headerName: 'Товар', width: 400,
      renderCell: (params) => {
        return `${params.row.product.name}`;
      }
    },
    {
      field: 'article', headerName: 'Артикул', width: 80,
      renderCell: (params) => {
        return `${params.row.product.article}`;
      }
    },
    {
      field: 'code1c', headerName: 'Код1c', width: 110,
      renderCell: (params) => {
        return `${params.row.product.code1c}`;
      }
    },
    {
      field: 'manager', headerName: 'Менеджер', width: 200,
      renderCell: (params) => {
        return (params.row.manager != undefined) ? `${params.row.manager.firstName} ${params.row.manager.lastName}` : '';
      }
    },
    {
      field: 'storage', headerName: 'Склад', width: 200,
      renderCell: (params) => {
        return `${params.row.storage.name}`;
      }
    },
    { field: 'count', headerName: 'Кількість', width: 200 },

  ];

  // Фільтрування
  const handleFilterSearch = () => {
    getRowsTable();
  };

  useEffect(() => {
    if (userData != false || storageData != false || productData != false) {
      setDisabled(false);
    };
    if (userData === false && storageData === false && productData === false) {
      setDisabled(true);
      getRowsTable();
    };
  }, [userData, storageData, productData]);

  const handleFilterReset = () => {
    setClear(true);
    setUserData(false);
    setProductData(false);
    setStorageData(false);
  };

  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CAccordion key={activeItem} activeItemKey={activeItem} flush>
            <CAccordionItem itemKey={1}>
              <CAccordionHeader><strong>Фільтр</strong></CAccordionHeader>
              <CAccordionBody>
                <CRow className="my-3 mx-2">
                  <CCol md={9} className="me-auto">
                    <CRow>
                      {/* <CCol md={4} className='filter-field'>
                          <CFormInput
                            placeholder="Назва" id="nameFilterSearch" aria-label="Пошук" className="me-2"
                            onChange={handleNameFilterChange} value={nameFilterSearch} onKeyDown={keyDown} autoComplete="off"
                          />
                        </CCol> */}
                      {/* <CCol md={4} className='filter-field'>
                          <CFormInput
                            placeholder="Контрагент" id="accountNameFilterSearch" aria-label="Пошук" className="me-2"
                            onChange={handleAccountNameFilterChange} value={accountNameFilterSearch} onKeyDown={keyDown} autoComplete="off"
                          />
                        </CCol> */}

                      <CCol md={4} className='filter-field'>
                        <FilterProduct setData={setProductData} clear={clear} />

                      </CCol>
                      <CCol md={4} className='filter-field'>
                        <FilterUser setData={setUserData} clear={clear} placeholder="Менеджер" />

                      </CCol>
                      <CCol md={4} className='filter-field'>
                        <FilterStorage setData={setStorageData} clear={clear} />

                      </CCol>
                    </CRow>
                  </CCol>
                  <CCol md={3} style={{ textAlign: "end" }}>
                    <CButton className="me-2" type="submit" color="secondary" onClick={handleFilterSearch} disabled={disabled}>Пошук</CButton>
                    <CButton type="submit" color="secondary" onClick={handleFilterReset}>X</CButton>
                  </CCol>
                </CRow>
              </CAccordionBody>
            </CAccordionItem>
          </CAccordion>
          <CCardHeader>
            <strong>Залишки</strong> <small></small>
          </CCardHeader>
          <CCardBody>
            <div style={{ height: 600, width: '100%' }}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={30}
                rowsPerPageOptions={[30]}
                pagination
                rowCount={rowCountState}
                paginationMode="server"
                onPageChange={handlePageChange}
              />
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default StorageBalance;