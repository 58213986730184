import { useState, useEffect } from "react";
import { cilSave } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import AxiosInstance from 'src/components/Axios';
import { Carousel } from 'react-responsive-carousel';
import AsyncSelect from 'react-select/async';

// Оновити конкретний продукт, який вже доданий до замовлення
const UpdateProductItem = (props) => {
    // console.log("UpdateProductItem props", props);

    const urlApi = '/order_products';
    const urlApi_2 = '/options';

    const [counter, setCounter] = useState(props.product.count);
    // Зображення товару
    const media = (props.product.product.media != undefined && props.product.product.media.length > 0) ? props.product.product.media : "";
    const code1c = (props.product != undefined && props.product.product.code1c != undefined) ? props.product.product.code1c : "";
    const barcode = (props.product != undefined && props.product.product.barcode != undefined) ? props.product.product.barcode : "";
    const article = (props.product != undefined && props.product.product.article != undefined) ? props.product.product.article : "";
    const measurementUnit = (props.product != undefined && props.product.product.measurement_unit != undefined) ? props.product.product.measurement_unit.short_name : "";
    // Ціна одиниці товару
    const productPrice = (props.product != undefined && props.product.priceForOne != undefined) ? props.product.priceForOne : "0";
    // Гуртові ціни
    const productPriceCategory = props?.product?.product?.priceCategory ? props.product.product.priceCategory : false;
    // Ціни товару
    const productPriceG3 = props?.product?.product?.priceCategory?.gurt_4 ?? '0'; // Гурт 3
    const productPriceG2 = props?.product?.product?.priceCategory?.gurt_3 ?? '0'; // Гурт 2
    const productPriceG1 = props?.product?.product?.priceCategory?.gurt_2 ?? '0'; // Гурт 1
    const productPriceVIP = props?.product?.product?.priceCategory?.gurt_1 ?? '0'; // Віп
    const productIncomePrice = props?.product?.product?.priceIncome ?? '0'; // Вхідна ціна одиниці товару
    // Рекомендована ціна
    const [recommendedPrice, setRecommendedPrice] = useState(0);
    // Ціна введена менеджером
    const [priceManager, setPriceManager] = useState(productPrice);
    // Змінені дані про товар, які будуть додані до замовлення
    const [productData, setProductData] = useState(false);
    // Сума замовлення по товару
    const sum = (priceManager * (isNaN(counter) ? 0 : counter)).toFixed(2);
    // Заблокувати кнопку "Додати до замовлення"
    const [disabledBtn, setDisabledBtn] = useState(false);
    // Головне зображення для продукта
    const [mainMedia, setMainMedia] = useState(
        (props.product.product != undefined && props.product.product.mainMedia != undefined && props.product.product.mainMedia != null) ?
            props.product.product.mainMedia
            : false
    );
    // Індекс головного зображення з масиву media
    const [indexMainMedia, setIndexMainMedia] = useState(0);
    // Вибране значення для AsyncSelect
    const [selectItems, setSelectItems] = useState({
        storage: {
            label: (props != undefined && props?.product?.storage) ? props.product.storage.name : '',
            value: (props != undefined && props?.product?.storage) ? props.product.storage.id : '',
        }
    });

    const [priceGroupArr, setPriceGroupArr] = useState(""); // Для "які ціни показувати"
    const optionKeyForPriceGroup = "show_price_group"; // Ключ з списком "які ціни показувати"

    // Які ціни показувати
    const [isShowGurt_3, setIsShowGurt_3] = useState(false);
    const [isShowGurt_2, setIsShowGurt_2] = useState(false);
    const [isShowGurt_1, setIsShowGurt_1] = useState(false);
    const [isShowVIP, setIsShowVIP] = useState(false);

    // Текст для popup
    const popupTextSuccess_1 = "Дані замовленого товару оновлено";
    const popupTextSuccess_2 = "Відкрито вікно редагування замовлення по товару";
    const popupTextSuccess_3 = "Вікно редагування замовлення по товару закрито";

    // Стилі для виділення рекомендованої ціни
    const boldStyle = {
        fontWeight: "bold"
    };

    // Відслідковувати які ціни показувати
    useEffect(() => {
        // console.log('priceGroupArr', priceGroupArr);
        if (priceGroupArr.length > 0) {
            const groupArr = priceGroupArr.split(',').map(el => el.trim().toLowerCase());
            // console.log('groupArr', groupArr);

            setIsShowGurt_3(groupArr.includes("3"));
            setIsShowGurt_2(groupArr.includes("2"));
            setIsShowGurt_1(groupArr.includes("1"));
            setIsShowVIP(groupArr.includes("vip"));
        };
    }, [priceGroupArr]);

    // Функція для запиту за датою прайсу та які ціни показувати
    const getShowPriceGroup = async (key) => {
        try {
            const response = await AxiosInstance.get(`${urlApi_2}?option_key=${key}`);
            // console.log('response', response);
            setPriceGroupArr(response.data.member [0].value)
        } catch (error) {
            console.error("Помилка запиту за 'які ціни показувати':", error);
        };
    };

    // Зробити запит за "які ціни показувати"
    useEffect(() => {
        getShowPriceGroup(optionKeyForPriceGroup);
    }, []);

    // Вивести повідомлення коли відкривається вікно з інформацією про товар
    useEffect(() => {
        // Popup про успішні зміни
        props.toastifySuccess(popupTextSuccess_2);
    }, [props.product.id]);

    // Збільшити кількість на 1
    function productIncrement() {
        setCounter(counter + 1);
    };
    // Зменшити кількість на 1
    function productDecrement() {
        if (counter > 2) {
            setCounter(counter - 1);
        }
        else {
            setCounter(1);
        }
    };

    // Зміна кількості шт. продуктів вручну
    const inputChange = (event) => {
        // console.log("inputChange event", event.target.value);
        // typeof event.target.value = string, перевести в тип number
        // console.log(event.target.value);
        // Перетворити з типу string в тип number
        // let value = parseFloat(event.target.value);
        // let count = (value <= 0.001) ? 1 : value;
        setCounter(event.target.value);
    };

    // Перевірка counter на NaN
    useEffect(() => {
        // console.log('counter', counter);
        if (isNaN(counter)) {
            setDisabledBtn(true);
        } else {
            setDisabledBtn(false);
        };
    }, [counter]);

    // Функція для кількості (Зміна кількості шт. продуктів вручну)
    const notEmpty = (event) => {
        if (event.target.value === "") {
            setCounter(1);
        } else {
            return;
        };
    };

    // Зміна ціни продукту вручну менеджером
    const priceChange = (event) => {
        // console.log("priceChange event", event.target.value);
        // typeof event.target.value = string, перевести в тип number
        let price = (event.target.value === "" || event.target.value < 0) ? 1 : parseFloat(event.target.value);
        setPriceManager(price);
    };

    // Формування даних про товар для додавання до замовлення
    useEffect(() => {
        setProductData({
            "count": counter,
            "priceForOne": priceManager,
            "sumOrdered": parseFloat(sum),
        });
    }, [counter, priceManager]);

    // Функція для вибору ціни для додавання до товару залежно від кількості
    const selectPriceForProduct = (product) => {
        // console.log('product', product);
        // console.log('product.count', product.count);

        let priceForProduct = 0;

        // Ціна одиниці товару
        // const productPriceR = (product != undefined && product.price != undefined) ? product.price : '0';
        const productPriceG3 = product?.priceCategory?.gurt_4 ? product.priceCategory.gurt_4 : '0';
        const productPriceG2 = product?.priceCategory?.gurt_3 ? product.priceCategory.gurt_3 : '0';
        const productPriceG1 = product?.priceCategory?.gurt_2 ? product.priceCategory.gurt_2 : '0';
        const productPriceVIP = product?.priceCategory?.gurt_1 ? product.priceCategory.gurt_1 : '0';

        if (product.priceCategory == undefined && product?.price) {
            priceForProduct = product.price;
        } else if (product?.priceCategory && product.count != undefined) {
            if (product.count < 100) {
                priceForProduct = productPriceG3;
            } else if (product.count >= 100 && product.count < 500) {
                priceForProduct = productPriceG2;
            } else if (product.count == 500 || product.count < 1000) {
                priceForProduct = productPriceG1;
            } else if (product.count >= 1000) {
                priceForProduct = productPriceVIP;
            }
        };

        // console.log('priceForProduct 1', priceForProduct);
        return priceForProduct
    };

    // Відслідковувати кількість для визначення рекомендованої ціни
    useEffect(() => {
        // console.log('counter', counter);
        // console.log('productPriceCategory', productPriceCategory);
        if (productPriceCategory != false) {
            let product = {
                ...props.product,
                count: counter,
                priceCategory: productPriceCategory
            };

            // Запустити функцію для вибору рекомендованої ціни
            let recommended = selectPriceForProduct(product);
            setRecommendedPrice(recommended);
        }
    }, [productPriceCategory, counter]);

    // Запит на API для оновлення даних про замовлений товар
    const putProductData = () => {
        let count = parseFloat(productData.count);
        if (count <= 0) {
            alert('Не вірна кількість!');
            return;
        }
        productData.count = count;
        AxiosInstance.put(`${urlApi}/${props.product.id}`, productData).then((response) => {
            if (response.status === 200) {
                // console.log("response", response);
                // alert("Дані оновлено");
                // Popup про успішні зміни
                props.toastifySuccess(popupTextSuccess_1);
                // Показати таблицю з доданими до замовлення товарами
                props.setShowTable(true);
                // Для запиту за списком з доданими товарами до замовлення
                props.setGetOP(true);
                // Для можливого повторного відкриття вікна редагування товару
                props.setOpenUpdateProduct(false);
                // Оновити дані по замовленню
                props.getOrder();
            };
        });
    };

    // Функція для закриття оновлення товару і відкриття модального вікна з списком доданих товарів
    const changeModal = () => {
        // Показати таблицю з доданими до замовлення товарами
        props.setShowTable(true);
        // Для запиту за списком з доданими товарами до замовлення
        props.setGetOP(true);
        // Для можливого повторного відкриття вікна редагування товару
        props.setOpenUpdateProduct(false);
        // Popup про успішні зміни (закриття вікна)
        props.toastifySuccess(popupTextSuccess_3);
    };

    // Функція для скидання змінених менеджером значень
    const cancel = () => {
        // Скинути кількість товару
        setCounter(props.product.count);
        // Скинути ціну товару
        setPriceManager(productPrice);
        // Скинути склад
        handleChange_2(
            {
                label: (props != undefined && props?.product?.storage) ? props.product.storage.name : '',
                value: (props != undefined && props?.product?.storage) ? props.product.storage.id : '',
            },
            "storage"
        )
    };

    // Визначити індекс головного зображення з масиву media
    useEffect(() => {
        // console.log('media', media);
        // console.log('mainMedia', mainMedia);
        if (media != undefined && media.length > 0 && mainMedia != undefined) {
            const index = media.findIndex(item => item.id === mainMedia.id);
            // console.log('index', index);
            if (index !== -1) {
                setIndexMainMedia(index);
            }
        }
    }, [media, mainMedia]);


    // --- Start - варіант для складів - статичний список без можливості введення name

    // // Вибрана категорія
    // const [selectedCategory, setSelectedCategory] = useState(null);
    // // Завантажити категорії
    // const loadStorage = () => {
    //     return AxiosInstance.get('/storages?pagination=false', { headers: { 'accept': 'application/json' } }).then((response) => {
    //         // console.log('storage get response', response);
    //         return response.data.map((result) => ({
    //             label: result.name,
    //             value: result.id,
    //         }));
    //     });
    // };

    // // Функція для вибору категорії з списку
    // const handleChange = (selectedCategory) => {
    //     // console.log('selectedCategory', selectedCategory);
    //     setSelectedCategory(selectedCategory);
    // };

    // useEffect(() => {
    //     // console.log('selectedCategory', selectedCategory);
    // }, [selectedCategory]);

    // --- End - варіант для складів - статичний список без можливості введення name


    // Склади, які приписані до менеджера
    const [defaultStoragesManager, setDefaultStoragesManager] = useState({});

    const loadOptionsStorage = async (inputValue) => {
        // let query = `name=${inputValue}`;
        let query = "";
        // if (inputValue === '') {
        //     query = 'name=null';
        // } else 
        if (inputValue != '') {
            query = `name=${inputValue}`
        }
        // if (inputValue == '' && role == 'ROLE_MANAGER')
        // query = `manager.id=${userData.id}`;
        // if (inputValue != '' && role == 'ROLE_MANAGER')
        // query += `&manager.id=${userData.id}`;
        // if (inputValue == '' && role == 'ROLE_ADMIN')
        // return {};

        let rez = await AxiosInstance.get(`/storages?pagination=false&${query}`, { headers: { 'accept': 'application/json' } }).then((response) => {
            // console.log("storages value get response", response);
            return response.data.map((result) => ({
                label: result.name,
                value: result.id,
            }));
        });
        setDefaultStoragesManager(rez);

        return rez;
    };

    // Функція для зміни значення Головного складу
    const handleChange_2 = (value, name) => {
        // console.log('value', value);
        // console.log('name', name);

        setProductData({
            ...productData,
            [name]: `/api/storages/${value.value}`
        });

        setSelectItems({
            ...selectItems,
            [name]: value
        });
    };

    return (
        <div style={{ position: "relative", padding: "10px" }}>
            <h2 className="mb-3 mx-2 orders-tableHeader">Редагувати замовлення по товару</h2>
            <span className="orders-updateProductItems__btn-close" onClick={changeModal} title="Закрити вікно редагування замовлення по товару">X</span>
            <h6>{props.product.name}</h6>

            <div className="row">
                <div className="col-xxl-8 col-xl-6">
                    {
                        (media != '') ?
                            <Carousel
                                className="me-3" style={{ height: '200px', width: 'auto' }}
                                showArrows={false} showThumbs={true} showStatus={false} thumbWidth={80}
                                selectedItem={indexMainMedia}
                            >
                                {
                                    // Відобразити всі зображення до продукту
                                    media.map(el => {
                                        return (
                                            <div key={el.id}>
                                                <img className="me-3" src={(media != '') ? process.env.REACT_APP_SERVER_URL + '' + el.contentUrl : ''} />
                                            </div>
                                        )
                                    })
                                }
                            </Carousel>
                            :
                            <div className="me-3 orders-updateProductItems__photo">
                                <img className="me-3" src={"/no-img.jpg"} height={200} />
                            </div>
                    }
                </div>
                <div className="col-xxl-4 col-xl-6">
                    {/* <div className="orders-update__text">Склад (категорії):
                        <AsyncSelect
                            cacheOptions
                            loadOptions={loadStorage}
                            defaultOptions
                            value={selectedCategory}
                            onChange={handleChange}
                            className="flex-grow-1 me-1 mb-2 orders-filterSize"
                            placeholder="Оберіть склад"
                        />
                    </div> */}
                    <div className="orders-update__text">Склад:
                        <AsyncSelect
                            cacheOptions
                            loadOptions={loadOptionsStorage}
                            // defaultOptions
                            value={selectItems.storage}
                            defaultOptions={defaultStoragesManager}
                            name="storage"
                            onChange={(value) => { handleChange_2(value, 'storage') }}
                            // className="flex-grow-1 me-1 mb-2 orders-filterSize"
                            className="flex-grow-1"
                            classNamePrefix='storage'
                            placeholder="Оберіть склад"
                        />
                    </div>

                    <div className="orders-update__text">Артикул: {article}</div>
                    <div className="orders-update__text">Штрихкод: {barcode}</div>
                    <div className="orders-update__text">Код1с: {code1c}</div>

                    {isShowGurt_3 &&
                        <div className="orders-update__text" style={(recommendedPrice === productPriceG3) ? boldStyle : {}}>
                            <span>Гурт 3: {productPriceG3} грн.</span>
                            {
                                (recommendedPrice === productPriceG3 && productPriceG3 != priceManager) &&
                                <button className="ms-2" onClick={() => setPriceManager(productPriceG3)}>Застосувати</button>
                            }
                        </div>
                    }
                    {isShowGurt_2 &&
                        <div className="orders-update__text" style={(recommendedPrice === productPriceG2) ? boldStyle : {}}>
                            Гурт 2: {productPriceG2} грн.
                            {
                                (recommendedPrice === productPriceG2 && productPriceG2 != priceManager) &&
                                <button className="ms-2" onClick={() => setPriceManager(productPriceG2)}>Застосувати</button>
                            }
                        </div>
                    }
                    {isShowGurt_1 &&
                        <div className="orders-update__text" style={(recommendedPrice === productPriceG1) ? boldStyle : {}}>
                            Гурт 1: {productPriceG1} грн.
                            {
                                (recommendedPrice === productPriceG1 && productPriceG1 != priceManager) &&
                                <button className="ms-2" onClick={() => setPriceManager(productPriceG1)}>Застосувати</button>
                            }
                        </div>
                    }
                    {isShowVIP &&
                        <div className="orders-update__text" style={(recommendedPrice === productPriceVIP) ? boldStyle : {}}>
                            VIP: {productPriceVIP} грн.
                            {
                                (recommendedPrice === productPriceVIP && productPriceVIP != priceManager) &&
                                <button className="ms-2" onClick={() => setPriceManager(productPriceVIP)}>Застосувати</button>
                            }
                        </div>
                    }
                    <div className="orders-update__text">Прихідна ціна: {productIncomePrice} грн.</div>
                    <div className="orders-update__text">
                        <span>Ціна, грн.: </span> <br />
                        <input className='mt-2 me-1 orders-update__input-price' type="number" pattern="[0-9]*" value={priceManager} onChange={priceChange} />
                    </div>
                    <div className="orders-update__text">
                        {
                            (priceManager !== recommendedPrice) &&
                            <span style={{ color: "red" }}>Вказана ціна не є рекомендованою (рекомендована ціна {recommendedPrice} грн.)</span>
                        }
                    </div>
                    <div className="orders-update__text">Сума: {sum} грн.</div>
                    <div className="mt-1 orders-update__text">
                        <span>Кількість, {measurementUnit}: </span> <br />
                        <div className="mt-2 d-flex">
                            <button className='me-1 orders-update__btn-counter' onClick={productDecrement} >-</button>
                            <input className='text-center orders-update__input-counter' type="number" min="0.001" step="any" value={counter} onChange={inputChange} onBlur={notEmpty} />
                            <button className='ms-1 orders-update__btn-counter' onClick={productIncrement}>+</button>
                        </div>
                    </div>
                    <div className="mt-3 d-flex justify-content-center align-items-center">
                        <button className="me-3" onClick={cancel} title="Скинути внесені зміни">Скинути</button>
                        <button onClick={putProductData} style={{ cursor: "pointer" }} title="Зберегти зміни" disabled={disabledBtn}>
                            <span className='me-2'>
                                <CIcon icon={cilSave} className="nav-icon" height={18} />
                            </span>
                            <span>Зберегти</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpdateProductItem;