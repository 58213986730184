import React, { useState, useEffect } from 'react';
import { Link, useLoaderData } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import { CCard, CCardBody, CCardHeader, CButton } from '@coreui/react';
import FormOrders from 'src/components/orders/FormOrders';
import ViewOrders from 'src/components/orders/ViewOrders';
import FormProductOrders from 'src/components/orders/FormProductOrders';
import ViewProductOrders from 'src/components/orders/ViewProductOrders/ViewProductOrders';
import BasicModal from 'src/components/Modal';
import MediaFrom from 'src/components/media/MediaFrom';
import ViewMediaOrders from 'src/components/orders/ViewMediaOrders';
import AddProductToOrder from 'src/components/orders/AddProductToOrder';
import { Spinner } from 'react-bootstrap';
import { GrStatusGood } from 'react-icons/gr';
import { confirmAlert } from 'react-confirm-alert';
import QuickProductAddition from 'src/components/orders/QuickProductAddition';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyToastify from 'src/components/myComponents/MyToactify';
import { Form } from 'react-bootstrap';
import { Modal, StyledEngineProvider, Typography, Box } from '@mui/material';
import UpdateProductItem from 'src/components/orders/UpdateProductItem';
import { GetStorages, CreateFromStorageElements } from 'src/components/orders/CreateFromStorageElements';
// import { getPrices } from 'src/components/product/prices/Prices';
/** Reduxe */
import { useDispatch, useSelector } from 'react-redux';
//import productReducer , { setPrices } from 'src/components/product/prices/productReducer';
import { setPrice, setBalance } from 'src/REDUX/orderSlice';
import { cilPrint } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import CalculationsOrders from 'src/components/orders/CalculationsOrders';


const urlApi = '/orders';
const urlRouter = '/orders';
const userData = localStorage.getItem('user_data');


export async function LoaderOrdersUpdate({ params }) {
  if (Object.keys(params).length != 0) {
    let res = await AxiosInstance.get(`${urlApi}/${params.pid}`).then((response) => {
      return response;
    });
    return res;
  }
  return false;
};

const formReducer = (state, event) => {
  return {
    ...state,
    [event.target.name]: event.target.value
  }
};


const OrdersForm = () => {
  const { data } = useLoaderData();
  const options = useSelector(state => state.options)
  const [MyData, setMyData] = useState(data);
  const [show, setShow] = useState((data == undefined) ? false : true);
  const [showModal, setShowModal] = useState(true);
  const [showModalSync, setShowModalSync] = useState(true);
  const [showModalMedia, setShowModalMedia] = useState(true);
  // Для запиту за order_products
  const [getOP, setGetOP] = useState(true);
  // // Сума замовлення
  // const [sumOrder, setSumOrder] = useState(0);
  // Перевірка чи замовлення є в системі 1с
  const [isOrderIn1c, setIsOrderIn1c] = useState(false); // якщо false, то є можливість редагування
  // Відкрити/закрити вікно для оновлення даних товару (кількість)
  const [openUpdateProduct, setOpenUpdateProduct] = useState(false);
  // Продукт для оновлення замовлення
  const [productForUpdate, setProductForUpdate] = useState(false);
  const [storages, setStorages] = useState(false);

  const [user, setUser] = useState(null);

  // Якщо замовлення створене менеджеоб без даних Контрагента, договору та адреси котрагента
  const [textMessage, setTextMessage] = useState("");

  // console.log('data', data);

  useEffect(() => {
    if (userData != null) {
      setUser(JSON.parse(userData));
    }
  }, []);

  // Функція для запиту за даними замовлення
  const getOrder = async () => {
    try {
      const response = await AxiosInstance.get(`${urlApi}/${MyData.id}`);
      // console.log('get orders response ', response);
      setMyData(response.data);
    } catch (error) {
      console.error("Помилка запиту за даними замовлення", error);
    };
  };

  /** Ціни спеціфікації  і Баланси */
  const dispatch = useDispatch();
  useEffect(() => {
    if (MyData != undefined && MyData.agreement != undefined && MyData.account != undefined) {
      let params = {
        'agreement.id': MyData.agreement.id,
        pagination: false
      };
      let specification_type = options.filter((word) => word.option_key === 'specification_type');
      if (specification_type.length > 0) {
        if (specification_type[0].value == 'virtual_agreement') {
          params = {
            'agreement.virtual': true,
            'agreement.account.id': MyData.account.id,
            pagination: false
          };
        }
      }


      dispatch(setBalance([]));
      AxiosInstance.get('prices', {
        params: params
      }).then((response) => {
        // console.log("server responce", response.data.member )
        if (response.data.member .length > 0) {
          dispatch(setPrice(response.data.member )); // Відправляємо ціни до Redux
        }

      })
      // console.log(JSON.parse(userData));
      if (userData != null) {
        let u = JSON.parse(userData);
        AxiosInstance.get('product_balance_in_storages', {
          params: {
            'manager.id': u.id,
            pagination: false
          }
        }).then((response) => {
          // console.log("server responce BALANCE - ", response.data.member )
          if (response.data.member .length > 0) {
            dispatch(setBalance(response.data.member )); // Відправляємо ціни до Redux
          }

        })
      }
    }
  }, [dispatch]);


  // Перевірка чи замовлення є в системі 1с
  useEffect(() => {
    if (MyData != undefined && (!MyData.synced || MyData.synced == null)) {
      // Замовлення ще не внесено в 1С
      setIsOrderIn1c(false);
    } else {
      // Замовлення вже в 1С
      setIsOrderIn1c(true);
    }
  }, [MyData]);


  // useEffect(() => {
  //   console.log("OrdersForm getOP", getOP);
  // }, [getOP]);

  // Функція для відкриття модального вікна
  const handleOpen = (el) => {
    // Відкрити модальне вікно
    setOpenUpdateProduct(true);
    // Визначити продукт для оновлення замовлення
    setProductForUpdate(el);
  };

  // Функція для закриття модального вікна
  const handleClose = () => {
    // Закрити модальне вікно для оновлення даних товару замовлення (кількість)
    setOpenUpdateProduct(false);
    // Очистити інформацію про товар для редагування
    setProductForUpdate(false);
  };

  // Викликає popup з власного компонента
  const popup = MyToastify();
  // Текст для popup
  // const popupTextSuccess = "Товар збережено";

  // Стилі для модального вікна
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 1,
  };

  useEffect(() => {
    setMyData(data);
  }, [data]);

  // useEffect(() => {
  //   console.log("sumOrder", sumOrder);
  // }, [sumOrder]);

  // const [name, setName] = useState((data != undefined) ? data.name : '');
  // const [synonym, setSynonym] = useState((data != undefined) ? data.synonym : '');
  // const [mainImg, setmainImg] = useState((data != undefined) ? data.images : '');


  const actionShowModal = (rez) => {
    setShowModal(rez);
    if (rez != true)
      console.log("Modal hide");
  };

  const getMyStorages = async () => {
    try {
      let storagesData = await GetStorages(MyData.id);
      setStorages(storagesData);
      console.log('storagesData', storagesData);
      // Ви можете працювати зі змінною storagesData, яка містить дані з GetStorages
    } catch (error) {
      console.error("Помилка при отриманні даних з GetStorages", error);
    }
  }

  const actionShowModalSync = (rez) => {
    setShowModalSync(rez);
    if (rez) {
      getMyStorages();
    }

    if (!rez) {
      console.log("Modal hide");
    }
  };

  const actionShowModalMedia = (rez) => {
    setShowModalMedia(rez);
    // console.log(11111);
    if (!rez)
      getMedia();
  };

  const syncedClick = (type) => {
    confirmAlert({
      title: (type) ? 'Ви зупиняєте передачу в 1с ?' : 'Ви впевнені що хочите передати дані в 1с ?',
      message: (type) ? 'дані які ви оновили в даному замовленні не потраплять в 1с.' : 'Після передавання даних в 1с, Редагувати замовлення на сайті не можна.',
      buttons: [
        {
          label: 'Так',
          onClick: () => {
            AxiosInstance.patch(`${urlApi}/${data.id}`, { synced: type })
            let res = AxiosInstance.get(`${urlApi}/${data.id}`).then((response) => {
              setMyData(response.data)
              return response;
            });
          }
        },
        {
          label: 'Ні',
          onClick: () => {
            return;
          }
        }
      ]
    });
  }
  const syncedApprovedClick = (type) => {
    confirmAlert({
      title: 'Увага!',
      message: 'Ви впевнені що хочете провестинакладну в 1с?',
      buttons: [
        {
          label: 'Так',
          onClick: () => {
            AxiosInstance.put(`${urlApi}/${data.id}`, { status: 'approved_in_1c' })
            let res = AxiosInstance.get(`${urlApi}/${data.id}`).then((response) => {
              setMyData(response.data)
              return response;
            });
          }
        },
        {
          label: 'Ні',
          onClick: () => {
            return;
          }
        }
      ]
    });
  }


  // Відслідковувати дані, які потрібно заповнити для синхронізації в 1с
  useEffect(() => {
    // console.log('MyData', MyData);
    let textArr = [];
    if (MyData != undefined) {
      if (MyData.account == undefined) {
        textArr.push("контрагент")
      }
      if (MyData.agreement == undefined) {
        textArr.push("договір")
      }
      if (MyData.shippingAddressCity == undefined) {
        textArr.push("адресу контрагента")
      }
    }
    // console.log('textArr', textArr);
    const textStr = textArr.join(", ");
    setTextMessage(textStr);
  }, [MyData]);

  return (
    <>
      {/* Popup про успішні зміни (справа зверху) */}
      <ToastContainer />

      <CCard className="mb-4">
        <CCardHeader>
          <div className='row'>
            <div className='col'>
              {/* <strong>Дані замовлення</strong> <small>{(MyData != undefined) ? `#${MyData.id}` : ''}</small> <br /> */}
              <strong>Id crm:</strong> {(MyData != undefined) ? `${MyData.id}` : ''}
              {(MyData != undefined && MyData.storage != undefined && MyData.storage != null) ? <p><strong className='ml-3'>Склад:</strong> {MyData.storage.name}</p> : ''}
            </div>
            <div className='col '>
              {
                (MyData != undefined && MyData.code1c != null) &&
                <Link to={`/orders/print/${MyData.id}`} target='_blank' className='mr-5 btn'>
                  <CIcon icon={cilPrint} customClassName="nav-icon" height={15} /> Друк
                </Link>
              }

            </div>
            <div className='col d-flex justify-content-end'>

              {(MyData != undefined) &&
                <>
                  <div className='mr-5'>

                  </div>

                  {
                    (MyData.status == 'is_forming') &&
                    <>
                      <>
                        {
                          (MyData.account == undefined || MyData.agreement == undefined || MyData.shippingAddressCity == undefined) &&
                          <div className='d-flex'>
                            <span className="me-3" style={{ color: "red" }}>Для синхронізації в 1с заповніть дані: {textMessage}</span>
                          </div>
                        }
                      </>

                      <div className='d-flex'>
                        {
                          (MyData.status == 'is_forming') &&
                          <BasicModal
                            show={showModal}
                            actionShowModal={actionShowModal}
                            title={(MyData != undefined) ? `Редагування замовлення №${MyData.id}.` : ''}
                            title2={(MyData != undefined && MyData.account != undefined) ? `Контрагент: ${MyData.account.name}.` : ''}
                            title3={(MyData != undefined && MyData.agreement != undefined) ? `Договір: ${MyData.agreement.name}.` : ''}
                            content={
                              <FormOrders
                                data={MyData} setMyData={setMyData} setShow={setShow}
                                toastifySuccess={popup.toastifySuccess} type="modal" setShowModal={setShowModal}
                              />
                            }
                            type="orders"
                            setGetOP={setGetOP}
                            btn_name="Редагувати"
                          />
                        }

                        {
                          (MyData.account != undefined && MyData.agreement != undefined && MyData.shippingAddressCity != undefined) &&
                          <span className="ms-2">
                            <BasicModal
                              show={showModalSync}
                              actionShowModal={actionShowModalSync}
                              title={'Увага'}
                              content={<CreateFromStorageElements storages={storages} id={MyData.id} setGetOP={setGetOP} />}
                              btn_name="🔄 Синхронізувати в 1с"
                            />
                          </span>
                        }
                      </div>
                    </>
                    //  <CButton className='ml-3' onClick={() => { syncedClick(false) }}>Синхронізувати в 1с</CButton>
                  }
                  <div className='d-flex justify-content-center align-items-center'>
                    {(MyData.status1c == 'save') && <>💾 Збережено в 1с </>}
                    {(MyData.status1c == 'approved') && <>✅ Проведено в 1с</>}
                    {(MyData.status1c == 'remove') && <>❌ позначено на видалення в 1с</>}
                    {(MyData.status1c == 'deleted ') && <>❌ видалено в 1с</>}

                    {(MyData.status == 'success_1c' && MyData.status1c == 'save') && <CButton style={{ marginLeft: '50px' }} onClick={() => { syncedApprovedClick(false) }}>✅ Провести в 1с</CButton>}
                    {/* { (MyData.status == 'approved_in_1c' && MyData.status1c == 'save') && <>Очікує синхронізації</>} */}
                    {((MyData.status1c == 'save' || MyData.status1c == null) && (MyData.status == 'synced_approved_in_1c' || MyData.status == 'synced_in_1c' || MyData.status == 'approved_in_1c')) && <>🔄 Очікує синхронізації</>}
                    {(MyData.status == 'is_forming') && <>📝формується</>}
                    {(MyData.status == 'error_approved' || MyData.status1c == 'error_approved') && <>❗ Помилка проведення, перевірте журнал 1с</>}
                  </div>
                </>
              }
            </div>
          </div>
        </CCardHeader>
        <CCardBody>
          {
            (show) ?
              <ViewOrders data={MyData}
              //  sumOrder={sumOrder}
              />
              :
              <FormOrders data={MyData} setShow={setShow} toastifySuccess={popup.toastifySuccess} />
          }
        </CCardBody>
      </CCard>
      {(data != undefined) ?
        <>
          <CCard className="mb-4">
            <CCardHeader >
              <strong>Товари 🛒</strong>
              <div className='d-flex mt-2'>

                {/* Модальне вікно для оновлення даних товару замовлення (кількість)*/}
                <StyledEngineProvider injectFirst>
                  <Modal
                    open={openUpdateProduct}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box className="order-updateProductItem" sx={style}>
                      <UpdateProductItem
                        product={productForUpdate} setShowTable={setOpenUpdateProduct} setGetOP={setGetOP} setOpenUpdateProduct={handleClose} toastifySuccess={popup.toastifySuccess}
                        getOrder={getOrder}
                      />
                    </Box>
                  </Modal>
                </StyledEngineProvider>

                <div>
                  {/* Додати/Оновити товар */}
                  {(show && MyData != undefined && (!MyData.synced || MyData.synced == null)) ?
                    <BasicModal
                      show={showModal}
                      actionShowModal={actionShowModal}
                      title={
                        <>
                          <div>
                            Редагування замовлення №${MyData.id}.
                          </div>
                          <div className="orders-modal__header2">
                            <div className="orders-modal__title2  ms-lg-0 ms-xl-3">
                              Контрагент: ${MyData.account?.name}.
                            </div>
                            <div className="orders-modal__title2 ms-md-3">
                              Договір: ${MyData.agreement?.name}.
                            </div>
                          </div>
                        </>
                      }
                      content={
                        <AddProductToOrder
                          data={MyData} getOP={getOP} setGetOP={setGetOP}
                          showModal={showModal} setShowModal={setShowModal}
                          // setSumOrder={setSumOrder}
                          getOrder={getOrder}
                          toastifySuccess={popup.toastifySuccess}
                          setProductForUpdate={setProductForUpdate}

                        />
                      }
                      type="orders"
                      setGetOP={setGetOP}
                      btn_name="Додати/Оновити"
                    />
                    : <></>}
                </div>
                <div className='ms-2'>
                  {/* Швидке додавання товару */}
                  {(show && MyData != undefined && (!MyData.synced || MyData.synced == null)) ?
                    <BasicModal
                      show={showModal}
                      actionShowModal={actionShowModal}
                      title={(MyData != undefined) ? `Додати товар до замовлення №${MyData.id}` : ''}
                      content={
                        // <FormProductOrders data={MyData} actionShowModal={actionShowModal} />
                        <QuickProductAddition data={MyData} setGetOP={setGetOP} setShowModal={setShowModal} toastifySuccess={popup.toastifySuccess}
                          getOrder={getOrder}
                        />
                      }
                      setGetOP={setGetOP}
                      btn_name="Швидке додавання"
                    />
                    : <></>}

                </div>
              </div>

            </CCardHeader>
            <CCardBody>
              {(MyData != undefined) ?
                (show) ?
                  <ViewProductOrders data={MyData} getOP={getOP} setGetOP={setGetOP} getOrder={getOrder} type="main"
                    // setSumOrder={setSumOrder}
                    toastifySuccess={popup.toastifySuccess} handleOpen={handleOpen} isOrderIn1c={isOrderIn1c} />
                  :
                  <AddProductToOrder data={MyData} getOrder={getOrder} />
                : <></>
              }
            </CCardBody>
          </CCard>
          {(MyData != undefined) ?
            <CCard className="mb-4">
              <CCardHeader>
                <strong>СЕРТИФІКАТИ ТА ДЕКЛАРАЦІЇ</strong>
                <BasicModal
                  show={showModalMedia}
                  actionShowModal={actionShowModalMedia}
                  title={`Додати сертифікат або деларацію до замовлення №${MyData.id}`}
                  content={
                    <MediaFrom order_id={MyData.id} actionShowModal={actionShowModalMedia} />
                  }
                />
              </CCardHeader>
              <CCardBody>
                <ViewMediaOrders data={MyData} showModalMedia={showModalMedia} />
              </CCardBody>
            </CCard>
            : <></>}

          {
            (MyData != undefined) &&
            <CCard className="mb-4">
              <CCardHeader>
                <strong>Акт звірок</strong>
              </CCardHeader>
              <CCardBody>
                <CalculationsOrders order_id={data.id} />
              </CCardBody>
            </CCard>
          }
        </>
        : ''
      }
    </>
  );
};

export default OrdersForm;