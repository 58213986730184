import React, { useState } from 'react';
import { CCard, CCardBody, CCardHeader, CCol, CRow, CButton } from '@coreui/react';
import { DataGrid } from '@mui/x-data-grid';
import { Link, useLoaderData } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import { cilPen, cilPlus, cilTrash } from '@coreui/icons';
import CIcon from '@coreui/icons-react';

const page = 1;
const postPerPage = 0;
const pageSize = 20;
const urlApi = '/measurment_units';
const urlRouter = '/measurment-unit';

export async function LoaderMeasurmentUnit({ params }) {
  let res = await AxiosInstance.get(`${urlApi}?page=${page}`).then((response) => {
    return response;
  });
  return res;
};

const MeasurmentUnit = () => {
  const { data } = useLoaderData();
  const [rows, setRows] = useState(false);
  const [rowCountState, setRowCountState] = React.useState(0);

  if (!rows) {
    setRowCountState(data['hydra:totalItems'])
    setRows(data.member );
  };

  const getRowsTable = (p = 1) => {
    AxiosInstance.get(urlApi + '?page=' + p,).then((response) => {
      setRows(response.data.member );
    });
  };

  const handlePageChange = (page) => {
    getRowsTable(page + 1);
  };

  function Deleted(row) {
    confirmAlert({
      title: 'Ви впевнені що хочите видалити цей пункт ?',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Так',
          onClick: () => {
            const response = AxiosInstance.delete(`${urlApi}/${row.id}`, header).then((response) => {
              getRowsTable();
            });
          }
        },
        {
          label: 'Ні',
          onClick: () => {
            return;
          }
        }
      ]
    });
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    {
      field: 'name', headerName: 'Назва', width: 200,
      renderCell: (params) => {
        return <Link to={`${urlRouter}/update/${params.row.id}`} style={{ textDecoration: "none", color: "rgba(0, 0, 0, 0.87)" }}>{params.row.name}</Link>;
      }
    },
    { field: 'code1c', headerName: 'code1c', width: 200 },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      renderCell: (params) => {
        // console.log("params:", params);
        return <Link to={`${urlRouter}/update/${params.row.id}`}><CButton color="dark" variant="outline"><CIcon icon={cilPen} customClassName="nav-icon" height={20} /></CButton></Link>;
      }
    },
  ];

  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardHeader>
            <strong>Одиниці виміру</strong> <small></small>
          </CCardHeader>
          <CCardBody>
            <p className="text-medium-emphasis small">
              <Link to={`${urlRouter}/create`}><CButton color="secondary"><CIcon icon={cilPlus} customClassName="nav-icon" height={15} />Створити</CButton></Link>
            </p>
            <div style={{ height: 600, width: '100%' }}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={30}
                rowsPerPageOptions={[30]}
                pagination
                rowCount={rowCountState}
                paginationMode="server"
                onPageChange={handlePageChange}
              />
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default MeasurmentUnit;