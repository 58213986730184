import { useState, useEffect } from "react";
import AxiosInstance from "../Axios";
import FilterProduct from "../filter/FilterProduct";
import { confirmAlert } from 'react-confirm-alert';
import { CListGroup, CListGroupItem, CButton } from '@coreui/react';


const urlApi = '/products_releteds';


const ProductRelated = ({ product }) => {
    const [productRelated, setProductRelated] = useState([]);
    const [newProduct, setNewProduct] = useState(false);

    const getData = () => {
        let rez = AxiosInstance.get(urlApi, { params: { 'product.id': product.id } }).then((response) => {
            // console.log("response", response);
            if (response.data.member  != undefined)
                setProductRelated(response.data.member )
        });
    }
    useEffect(() => {
        getData();
    }, []);

    const handleSave = () => {
        // Перевірка, чи вибрана категорія вже належить до даного продукту
        let enters = productRelated.length > 0 ? productRelated.some(el => el.relatedProduct.id == newProduct.value) : false;
        if (enters) {
            alert("Вибрана категорія вже належить до даного продукту");
        } else {
            // Вибрана нова категорія
            let newProd = `api/products/${newProduct.value}`;


            // Оновити категорії для продукту
            AxiosInstance.post(urlApi, {
                'product': `api/products/${product.id}`,
                'relatedProduct': newProd
            }).then((response) => {
                // console.log("response put", response);
                if (response.status == 201) {
                    // Popup про успішні зміни
                    // props.toastifySuccess(popupTextAddSuccess);
                    // alert("Дані оновлено");
                    getData();
                }
            });
        };
    };
    function Deleted(row) {
        confirmAlert({
            title: 'Ви впевнені що хочите видалити цей пункт ?',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Так',
                    onClick: () => {
                        // console.log("delete row id", row);
                        const response = AxiosInstance.delete(urlApi + '/' + row).then((response) => {
                            getData();
                        });
                    }
                },
                {
                    label: 'Ні',
                    onClick: () => {
                        return;
                    }
                }
            ]
        });
    };
    let i = 0;
    return (
        <>
            <div className="row">
                <div className="col">
                    <h3>Супутні товари</h3>
                </div>
                <div className="col d-flex justify-content-end">
                    <FilterProduct setData={setNewProduct} spec={true} />
                    <CButton onClick={handleSave} className="mb-2" color="secondary">+</CButton>
                </div>
            </div>


            {
                (productRelated.length > 0) ?
                    <CListGroup className="mb-3">
                        {
                            productRelated.map((v, k) => {
                                i++;
                                return (
                                    <CListGroupItem key={k} className="d-flex justify-content-between align-items-center">
                                        {/* <Link to={`/products/update/${v.id}`} >{`${i})`} {v.name}</Link> */}
                                        <div>{`${i})`} <strong>{v.relatedProduct.name}</strong></div>
                                        <CButton color="danger" variant="outline" onClick={() => { Deleted(v.id) }}>
                                            Видалити
                                        </CButton>
                                    </CListGroupItem>
                                )
                            })
                        }
                    </CListGroup>
                    :
                    <p className="ml-3">Товарів не знайдено</p>
            }
        </>
    );
}

export default ProductRelated;