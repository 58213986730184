import { useState, useEffect } from "react";
import AxiosInstance from "../Axios";
import 'react-toastify/dist/ReactToastify.css';
import { CButton, CFormSelect } from "@coreui/react";
import { Carousel } from 'react-responsive-carousel';
import { Link } from "react-router-dom";
import { cilTrash } from '@coreui/icons';
import CIcon from '@coreui/icons-react';

const GalleryObject = ({ dataId, currentGalleries, popup }) => {

const [galleryList, setGalleryList] = useState([])
const [gallery, setGallery] = useState(false);
const [galleryObject, setGalleryObject] = useState(currentGalleries);

  useEffect(()=>{
    AxiosInstance.get(`/galleries?pagination=false`).then((responce) => {
        if (responce.status == 200) {
            const gallerySelect = responce.data['hydra:member'].map((gallery)=>{
                return {label: gallery.name, value: gallery.id}
            })
            setGalleryList([{label: 'Оберіть галерею', value: false}, ...gallerySelect]);
        }
      });
  },[])

  function addGallery() {
    if(gallery) {
        AxiosInstance.get(`/galleries/${gallery}`).then((responce) => {
            if (responce.status == 200) {
                setGalleryObject([...galleryObject, responce.data]);
                popup.toastifySuccess('Галерею додано');
            }
          });
    }
  }

  function postGallery() {
    const galleries =  galleryObject.map(gallery => gallery['@id']);
    if(galleryObject.length > 0) {
      AxiosInstance.put(`/objects/${dataId}`, {galleries: galleries}).then((response)=>{
        popup.toastifySuccess('Галерею збережено');
      });
    }
  }

  function removeGallery(id) {
    const filtredGalleries = galleryObject.filter(gallery => gallery.id !== id);
    setGalleryObject(filtredGalleries);
  }

  return (
    <div className="gallery-object">
    <div className="mb-3 d-flex align-items-center gap-3">
        <CFormSelect
            aria-label="gallery-object__select"
            onChange={(e) => setGallery(e.target.value)}
            defaultValue={false}
            required
            options={galleryList}
            style={{maxWidth: '500px', height: '100%'}}
          />
          <button type="button" onClick={addGallery} className="mb-2 mt-2 btn btn-secondary">+</button>
    </div>
    {galleryObject.length > 0 &&
    <div className="gallery-object__inner">
        {galleryObject.map(((gallery, index) => {
            return  <div className="gallery-object__item">
                    <div className="gallery-object__btn">
                      <Link className="btn btn-secondary" to={`/gallery/update/${gallery.id}`} target="_blank">Редагувати 🖊</Link>
                      <CButton color="danger" variant="outline" onClick={() => {removeGallery(gallery.id)}}>
                        <CIcon icon={cilTrash} customClassName="nav-icon" height={20} />
                      </CButton>
                    </div>
                    <Carousel
                        className="me-3"
                        style={{ height: '300px', width: 'auto' }}
                        showArrows={true}
                        width={500}
                        showStatus={false}
                        key={index}
                        // selectedItem={indexMainMedia}
                    >
                    {gallery.galleryItems.map(item => {
                        return <img key={item.id} src={process.env.REACT_APP_SERVER_URL + item.media?.contentUrl} alt="gallery" />
                    })}    
                    </Carousel>
             </div>
        }))}
    </div>}
    <CButton type="submit" onClick={postGallery} color="success">Зберегти</CButton>
    </div>
  );
};

export default GalleryObject;