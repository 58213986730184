import React, { useEffect, useState } from 'react';
import { CCard, CCardBody, CCardHeader, CCol, CRow, CButton, CFormSelect, CForm, CInputGroup, CFormLabel, CFormInput, CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody, CFormCheck } from '@coreui/react';
import { DataGrid } from '@mui/x-data-grid';
import { Link, useLoaderData } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import { cilPen, cilPlus, cilTrash } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { AiOutlineEye } from "react-icons/ai";

const urlApi = '/faqs';
const urlRouter = '/useful-materials';
const cabinetLink = process.env.REACT_APP_SITE_URL;

const page = 1;
const itemsPerPage = 30;

export async function LoaderUsefulMaterials({ params }) {
  let res = await AxiosInstance.get(urlApi, {
    params: {
      page: page,
      itemsPerPage: itemsPerPage,
    }
  }).then((response) => {
    return response;
  });
  return res;
};

const UsefulMaterials = () => {
  const { data } = useLoaderData();
  const [rows, setRows] = useState(false);
  const [rowCountState, setRowCountState] = React.useState(0);
  const [nameFilterSearch, setNameFilterSearch] = useState('');
  // Заблокувати кнопку фільрації якщо не введені символи для пошуку
  const [isDisabledBtnFilter, setIsDisabledBtnFilter] = useState(true);
  const [isDisabledBtnClear, setIsDisabledBtnClear] = useState(true);
  const [showLoader, setShowLoader] = useState(false);

  if (!rows) {
    setRowCountState(data['hydra:totalItems']);
    setRows(data.member );
  };

  // Запит за продуктами (+фільтр)
  const getRowsTable = (p = 1, clear = false) => {
    setShowLoader(true);
    let params = {
      itemsPerPage: itemsPerPage,
      page: p,
    };

    if (clear == false) {
      if (nameFilterSearch != '') {
        params['name'] = nameFilterSearch;
      };
    }
    // console.log("request", request);
    AxiosInstance.get(urlApi, { params: params }).then((response) => {
      // console.log('get faqs response', response);
      // AxiosInstance.get(urlApi).then((response) => {
      setRows(response.data.member );
      setRowCountState(response.data['hydra:totalItems']);
      setShowLoader(false);
    });
  };

  const handlePageChange = (page) => {
    getRowsTable(page + 1);
  };

  // Заблокувати кнопку фільрації якщо не введені символи для пошуку
  const disabledButton = (event) => {
    if (event.target.value) {
      setIsDisabledBtnFilter(false);
    } else {
      setIsDisabledBtnFilter(true);
    }
  };

  // Якщо хоч одне поле пошуку заповнене, то розблокувати кнопку пошуку
  useEffect(() => {
    if (nameFilterSearch != "") {
      setIsDisabledBtnFilter(false);
    };
  }, [nameFilterSearch]);

  // ----- Start handleFilterChange -----
  const handleNameFilterChange = (event) => {
    setNameFilterSearch(event.target.value);
    disabledButton(event);
  };
  // ----- End handleFilterChange -----

  // Фільтрування по name
  const handleFilterSearch = (event) => {
    // console.log("handleFilterSearch event", event)
    event.preventDefault();
    getRowsTable();
    setIsDisabledBtnClear(false);
  };

  // Скинути фільтр
  const handleFilterReset = () => {
    setShowLoader(true);
    setNameFilterSearch("");
    setIsDisabledBtnFilter(true);
    getRowsTable(1, true);
    setIsDisabledBtnClear(true);
  };

  function Deleted(row) {
    confirmAlert({
      title: 'Ви впевнені що хочите видалити цей пункт ?',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Так',
          onClick: () => {
            const response = AxiosInstance.delete(`${urlApi}/${row.id}`).then((response) => {
              getRowsTable();
            });
          }
        },
        {
          label: 'Ні',
          onClick: () => {
            return;
          }
        }
      ]
    });
  };

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 50,
      // renderCell: (params) => {
      //   return (
      //     <div style={{ display: "flex", flexDirection: "column" }}>
      //       <Link
      //         to={'/products/read/' + params.row.id} title="Перейти на товар в новому вікні" target='_blank'
      //       >
      //         {params.row.id} <RxOpenInNewWindow />
      //       </Link>
      //     </div>
      //   )
      // }
    },
    {
      field: 'name', headerName: 'Назва', width: 600,
      editable: true,
    },
    {
      field: "action_1",
      headerName: "Перегляд",
      sortable: false,
      width: 80,
      renderCell: (params) => {
        return (
          <Link to={`${urlRouter}/read/${params.row.id}`} title="Перейти до перегляду матеріалу">
            <CButton color="dark" variant="outline">
              <AiOutlineEye size={20} />
            </CButton>
          </Link>
        );
      }
    },
  ];

  // Натиснення кнопки: "Enter" - виконати пошук; "Escape" - скинути пошук 
  const keyDown = (event) => {
    // Виконати пошук
    if (event.key === 'Enter' && !isDisabledBtnFilter) {
      event.preventDefault();
      handleFilterSearch(event);
    };
    // Скинути пошук
    if (event.key === 'Escape') {
      event.preventDefault();
      handleFilterReset(event);
    };
  };

  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CAccordion flush>
            <CAccordionItem>
              <CAccordionHeader><strong>Фільтр</strong></CAccordionHeader>
              <CAccordionBody>
                <CRow className="my-3 mx-2">
                  <CCol md={9} className="me-auto">
                    <CRow>
                      <CCol md={3} className='filter-field'>
                        <CFormInput
                          placeholder="Назва" id="nameFilterSearch" aria-label="Пошук" className="me-2"
                          onChange={handleNameFilterChange} value={nameFilterSearch} onKeyDown={keyDown} autoComplete="off"
                        />
                      </CCol>

                    </CRow>
                  </CCol>
                  <CCol md={3} style={{ textAlign: "end" }}>
                    <CButton className="me-2" type="submit" color="secondary" onClick={handleFilterSearch} disabled={isDisabledBtnFilter}>Пошук</CButton>
                    <CButton type="submit" color="secondary" onClick={handleFilterReset} disabled={isDisabledBtnClear}>X</CButton>
                  </CCol>
                </CRow>
              </CAccordionBody>
            </CAccordionItem>
          </CAccordion>

          <CCardBody>
            <p className="text-medium-emphasis small">
              <Link to="/useful-materials/create"><CButton color="secondary"><CIcon icon={cilPlus} customClassName="nav-icon" height={15} />Створити</CButton></Link>
            </p>
            <div style={{ height: 600, width: '100%' }}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={itemsPerPage}
                rowsPerPageOptions={[itemsPerPage]}
                pagination
                rowCount={rowCountState}
                loading={showLoader}
                paginationMode="server"
                onPageChange={handlePageChange}
              />
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default UsefulMaterials;
