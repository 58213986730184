import React, { useState } from 'react';
import { CCard, CCardBody, CCardHeader, CFormInput, CCol, CRow, CButton, CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody } from '@coreui/react';
import { DataGrid } from '@mui/x-data-grid';
import { Link, useLoaderData } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import { cilPen, cilPlus, cilTrash } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { useEffect } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import useOpenAccordion from 'src/components/store/useOpenAccordion';
import PermissionsNoPage, {Permissions} from "src/components/Permissions";

const page = 1;
const postPerPage = 0;
const pageSize = 20;
const urlApi = '/accounts';
const urlApi_2 = '/agreements';
const urlRouter = '/accounts';

const role = localStorage.getItem('user_role');
const userData = JSON.parse(localStorage.getItem('user_data'));

export async function LoaderAccounts({ params }) {
  let query = `page=${page}`;
  if (role == 'ROLE_MANAGER')
    query += `&manager.id=${userData.id}`;

  let res = await AxiosInstance.get(`${urlApi}?${query}`).then((response) => {
    return response;
  });
  return res;
}

const Accounts = ({user_data}) => {
  if(!Permissions(user_data, 'Accounts', 'VIEW_ACCOUNTS')){
    return <PermissionsNoPage />;
  }
  const { data } = useLoaderData();
  const [rows, setRows] = useState(false);
  const [rowCountState, setRowCountState] = React.useState(0);
  const [nameFilterSearch, setNameFilterSearch] = useState('');
  const [managerFirstNameFilterSearch, setManagerFirstNameFilterSearch] = useState('');
  const [edrpouSearch, setEdrpouSearch] = useState('');
  // Заблокувати кнопку фільрації якщо не введені символи для пошуку
  const [disabled, setDisabled] = useState(true);

  // Відслідковувати стартове значення стану акордиону в залежності від розширення екрану
  const activeAccordionItem = useOpenAccordion();
  const activeItem = activeAccordionItem.activeItem;

  if (!rows) {
    setRowCountState(data['hydra:totalItems']);
    setRows(data.member );
  };

  // Запит за контрагентами (+фільтр)
  const getRowsTable = (p = 1) => {
    let request = ``;
    if (nameFilterSearch != '') {
      request += `&name=${nameFilterSearch}`;
    };
    if (managerFirstNameFilterSearch != '') {
      request += `&manager.firstName=${managerFirstNameFilterSearch}`;
    };
    if (edrpouSearch != '') {
      request += `&edrpou=${edrpouSearch}`;
    };
    if (role == 'ROLE_MANAGER')
      request += `&manager.id=${userData.id}`;

    // console.log("request", request);
    AxiosInstance.get(`${urlApi}?page=${p}${request}`).then((response) => {
      setRows(response.data.member );
      setRowCountState(response.data['hydra:totalItems']);
    });
  };

  const handlePageChange = (page) => {
    getRowsTable(page + 1);
  };

  // Заблокувати кнопку фільтрації, якщо не введені символи для пошуку
  const disabledButton = event => {
    if (event.target.value) {
      setDisabled(false);
    } else {
      setDisabled(true);
    };
  };

  // Якщо хоч одне поле пошуку заповнене, то розблокувати кнопку пошуку
  useEffect(() => {
    if (nameFilterSearch != "" || managerFirstNameFilterSearch != "" || edrpouSearch !== "") {
      setDisabled(false);
    };
  }, [nameFilterSearch, managerFirstNameFilterSearch, edrpouSearch]);

  // ----- Start handleFilterChange -----
  const handleNameFilterChange = event => {
    setNameFilterSearch(event.target.value);
    disabledButton(event);
  };
  const handleManagerFirstNameFilterChange = event => {
    setManagerFirstNameFilterSearch(event.target.value);
    disabledButton(event);
  };
  const handleEdrpouChange = event => {
    setEdrpouSearch(event.target.value);
    disabledButton(event);
  };
  // ----- End handleFilterChange -----

  // Фільтрування
  const handleFilterSearch = () => {
    getRowsTable();
  };

  // Скинути фільтр
  const handleFilterReset = () => {
    setNameFilterSearch("");
    setManagerFirstNameFilterSearch("");
    setEdrpouSearch("");
    setDisabled(true);
    getRowsTable();
  };

  // Функція для видалення договору
  const deleteAccount = (row) => {
    AxiosInstance.delete(`${urlApi}/${row.id}`).then((response) => {
      // console.log('delete accounts response', response);
      if (response.status === 204) {
        getRowsTable();
      } else {
        console.log("Помилка видалення контрагента");
      }
    })
  };

  function Deleted(row) {
    confirmAlert({
      // title: 'Ви впевнені що хочите видалити цей пункт ?',
      title: `Ви впевнені що хочите видалити контрагента "${row.name}"?`,
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Так',
          onClick: () => {
            // const response = AxiosInstance.delete(urlApi + '/' + row.id, header).then((response) => {
            //   getRowsTable();
            // });
            deleteAccount(row);
          }
        },
        {
          label: 'Ні',
          onClick: () => {
            return;
          }
        }
      ]
    });
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    {
      field: 'name', headerName: 'Назва', width: 200,
      renderCell: (params) => {
        return <Link to={`${urlRouter}/update/${params.row.id}`} style={{ textDecoration: "none", color: "rgba(0, 0, 0, 0.87)" }} title="Редагувати контрагента">{params.row.name}</Link>;
      }
    },
    // { field: 'code1c', headerName: 'Код 1с', width: 100 },
    {
      field: "manager",
      headerName: "Менеджер",
      sortable: false,
      width: 300,
      renderCell: (params) => {
        return (params.row.manager != undefined) ? `(${params.row.manager.username}) ${params.row.manager.firstName} ${params.row.manager.firstName}` : '';
      }
    },
    { field: 'description', headerName: 'Опис', width: 200 },
    { field: 'type', headerName: 'Тип', width: 100 },
    { field: 'edrpou', headerName: 'ЄДРПОУ', width: 100 },
    // { field: 'maxBorg', headerName: 'Макс. Борг', width: 100 },
    // { field: 'dayDelay', headerName: 'Днів від терм.', width: 150 },

    {
      field: "action",
      headerName: "Action",
      sortable: false,
      width: 70,
      renderCell: (params) => {
        return <Link to={`${urlRouter}/update/${params.row.id}`}><CButton color="dark" variant="outline" title="Редагувати контрагента"><CIcon icon={cilPen} customClassName="nav-icon" height={20} /></CButton></Link>;
      }
    },
    {
      field: "delete",
      headerName: "Видалити",
      sortable: false,
      renderCell: (params) => {
        // console.log('params', params);
        if (params.row.code1c !== undefined && params.row.code1c != "") {
          return
        } else {
          return <CButton color="danger" variant="outline" onClick={() => {
            Deleted(params.row)
          }
          } title="Видалити контрагента"><CIcon icon={cilTrash} customClassName="nav-icon" height={20} /></CButton>;
        }
      }
    },
  ];

  // Натиснення кнопки: "Enter" - виконати пошук; "Escape" - скинути пошук 
  const keyDown = (event) => {
    // Виконати пошук
    if (event.key === 'Enter' && !disabled) {
      event.preventDefault();
      handleFilterSearch(event);
    };
    // Скинути пошук
    if (event.key === 'Escape') {
      event.preventDefault();
      handleFilterReset(event);
    };
  };

  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CAccordion key={activeItem} activeItemKey={activeItem} flush>
            <CAccordionItem itemKey={1}>
              <CAccordionHeader><strong>Фільтр</strong></CAccordionHeader>
              <CAccordionBody>
                <CRow className="my-3 mx-2">
                  <CCol md={9} className="me-auto">
                    <CRow>
                      <CCol md={4} className='filter-field'>
                        <CFormInput
                          placeholder="Назва" id="nameFilterSearch" aria-label="Пошук" className="me-2"
                          onChange={handleNameFilterChange} value={nameFilterSearch} onKeyDown={keyDown} autoComplete="off"
                        />
                      </CCol>
                      {/* <CCol md={4} className='filter-field'>
                        <CFormInput
                          placeholder="Менеджер" id="managerFirstNameSearch" aria-label="Пошук" className="me-2"
                          onChange={handleManagerFirstNameFilterChange} value={managerFirstNameFilterSearch} onKeyDown={keyDown} autoComplete="off"
                        />
                      </CCol> */}
                      <CCol md={4} className='filter-field'>
                        <CFormInput
                          placeholder="ЄДРПОУ" id="edrpouSearch" aria-label="Пошук" className="me-2"
                          onChange={handleEdrpouChange} value={edrpouSearch} onKeyDown={keyDown} autoComplete="off"
                        />
                      </CCol>
                    </CRow>
                  </CCol>
                  <CCol md={3} style={{ textAlign: "end" }}>
                    <CButton className="me-2" type="submit" color="secondary" onClick={handleFilterSearch} disabled={disabled}>Пошук</CButton>
                    <CButton type="submit" color="secondary" onClick={handleFilterReset}>X</CButton>
                  </CCol>
                </CRow>
              </CAccordionBody>
            </CAccordionItem>
          </CAccordion>

          <CCardHeader>
            <strong>Контрагенти</strong> <small></small>
          </CCardHeader>
          <CCardBody>
            <p className="text-medium-emphasis small">
              <Link to={`${urlRouter}/create`}><CButton color="secondary"><CIcon icon={cilPlus} customClassName="nav-icon" height={15} />Створити</CButton></Link>
            </p>
            <div style={{ height: 600, width: '100%' }}>

              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={30}
                rowsPerPageOptions={[30]}
                pagination
                rowCount={rowCountState}
                paginationMode="server"
                onPageChange={handlePageChange}
              />
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default Accounts;