import React, { useEffect, useState } from 'react';
import { CCard, CCardBody, CCardHeader, CFormInput, CCol, CRow, CButton, CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody } from '@coreui/react';
import { DataGrid } from '@mui/x-data-grid';
import { Link, useLoaderData } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import { cilPen, cilPlus, cilTrash, cilClone, cilPrint } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { GiShoppingCart } from 'react-icons/gi';
import { GrEdit } from 'react-icons/gr';
import CopyOrder from 'src/components/orders/CopyOrder';
import OffcanvasOrder from 'src/components/orders/OffcanvasOrder';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyToastify from 'src/components/myComponents/MyToactify';
import { confirmAlert } from 'react-confirm-alert';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import useOpenAccordion from 'src/components/store/useOpenAccordion';
import useCurrency from 'src/components/store/useCurrency';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Button from 'react-bootstrap/Button';




dayjs.extend(utc);


const page = 1;
const postPerPage = 0;
const pageSize = 20;

const urlApi = '/orders';
const urlRouter = '/orders';

const role = localStorage.getItem('user_role');
const user_data = JSON.parse(localStorage.getItem('user_data'));
const user_id = (user_data != null && user_data != undefined && user_data.id != undefined) ? user_data.id : false;
const defaultUserFilter = (role == 'ROLE_MANAGER' && user_id) ? `&account.manager=${user_id}` : '';

export async function LoaderOrders({ params }) {
  let res = await AxiosInstance.get(urlApi + '?page=' + page + defaultUserFilter).then((response) => {
    return response;
  });
  return res;
}

const Orders = () => {
  const { data } = useLoaderData();
  const [rows, setRows] = useState(false);
  const [rowCountState, setRowCountState] = React.useState(0);
  const [code1cFilterSearch, setCode1cFilterSearch] = useState('');
  const [accountsFilterSearch, setAccountsFilterSearch] = useState('');
  const [managerFilterSearch, setManagerFilterSearch] = useState('');
  // Заблокувати кнопку фільрації якщо не введені символи для пошуку
  const [disabled, setDisabled] = useState(true);
  // Для клонування замовлення
  const copyOrder = CopyOrder();
  // Викликає popup з власного компонента
  const popup = MyToastify();
  // Текст для popup
  const popupTextSuccess_1 = "Замовлення склоновано";
  const popupTextSuccess_2 = "Замовлення видалено";

  // Відслідковувати стартове значення стану акордиону в залежності від розширення екрану
  const activeAccordionItem = useOpenAccordion();
  const activeItem = activeAccordionItem.activeItem;

  // Для клонування замовлення
  useEffect(() => {
    // console.log('copyOrder', copyOrder);
    if (copyOrder.updateOrdersList === true) {
      // Вивести popup про успішне клонування замовлення
      popup.toastifySuccess(popupTextSuccess_1);
      // Оновити таблицю з замовленнями
      getRowsTable();
    };
  }, [copyOrder]);

  if (!rows) {
    setRowCountState(data['hydra:totalItems'])
    setRows(data.member );
  }

  // Запит за замовленнями (+фільтр)
  const getRowsTable = (p = 1) => {
    let request = ``;
    if (code1cFilterSearch != '') {
      request += `&code1c=${code1cFilterSearch}`;
    };
    if (accountsFilterSearch != '') {
      request += `&account.name=${accountsFilterSearch}`;
    };
    if (managerFilterSearch != '') {
      request += `&manager.firstName=${managerFilterSearch}`;
    };
    // console.log("request", request);
    AxiosInstance.get(`${urlApi}?page=${p}${request}` + defaultUserFilter).then((response) => {
      setRows(response.data.member );
      setRowCountState(response.data['hydra:totalItems']);
      // "Перезарядити" функцію для клонування замовлення
      copyOrder.setUpdateOrdersList(false);
    });
  };

  const handlePageChange = (page) => {
    getRowsTable(page + 1);
  };

  // Заблокувати кнопку фільтрації, якщо не введені символи для пошуку
  const disabledButton = event => {
    if (event.target.value) {
      setDisabled(false);
    } else {
      setDisabled(true);
    };
  };

  // Якщо хоч одне поле пошуку заповнене, то розблокувати кнопку пошуку
  useEffect(() => {
    if (code1cFilterSearch != "" || accountsFilterSearch != "" || managerFilterSearch != "") {
      setDisabled(false);
    }
  }, [code1cFilterSearch, accountsFilterSearch, managerFilterSearch]);

  // ----- Start handleFilterChange -----
  const handleCode1cFilterChange = event => {
    setCode1cFilterSearch(event.target.value);
    disabledButton(event);
  };

  const handleAccountsFilterChange = event => {
    setAccountsFilterSearch(event.target.value);
    disabledButton(event);
  };

  const handleManagerFilterChange = event => {
    setManagerFilterSearch(event.target.value);
    disabledButton(event);
  };
  // ----- End handleFilterChange -----

  // Фільтрування по code1c, accounts та manager
  const handleFilterSearch = () => {
    getRowsTable();
  };

  // Скинути фільтр
  const handleFilterReset = () => {
    setCode1cFilterSearch("");
    setAccountsFilterSearch("");
    setManagerFilterSearch("");
    setDisabled(true);
    getRowsTable();
  };

  function Deleted(row) {
    // console.log('row', row);
    confirmAlert({
      title: 'Ви впевнені що хочите видалити цей пункт ?',
      // message: 'Are you sure to do this.',
      message: (
        <>
          <div>Замовлення №{row.id}</div>
          <div>Контрагент: {row.account.name}</div>
          <div>Договір: {row.agreement.name}</div>
          <div>Менеджер: {row.manager.firstName} {row.manager.lastName}</div>
        </>
      ),
      buttons: [
        {
          label: 'Так',
          onClick: () => {
            const response = AxiosInstance.delete(urlApi + '/' + row.id).then((response) => {
              getRowsTable();
              // Вивести popup про успішне видалення замовлення
              popup.toastifySuccess(popupTextSuccess_2);
            });
          }
        },
        {
          label: 'Ні',
          onClick: () => {
            return;
          }
        }
      ]
    });
  };

  const columns = [
    {
      field: '1c',
      headerName: '#',
      width: 10,
      renderCell: (params) => {
        let s = params.row;
        return <>
          {(s.status1c == 'save') && <a onClick={() => { handleShow(s) }}>💾</a>}
          {(s.status1c == 'approved') && <a onClick={() => { handleShow(s) }}> ✅</a>}
          {(s.status1c == 'remove') && <a onClick={() => { handleShow(s) }}> ❌</a>}
          {(s.status1c == 'deleted ') && <a onClick={() => { handleShow(s) }}> ❌</a>}
          {((s.status1c == 'save' || s.status1c == null) && (s.status == 'synced_approved_in_1c' || s.status == 'synced_in_1c' || s.status == 'approved_in_1c')) && <a onClick={() => { handleShow(s) }}> 🔄</a>}
          {(s.status == 'is_forming') && <a onClick={() => { handleShow(s) }}> 📝</a>}
          {(s.status == 'error_approved' || s.status1c == 'error_approved') && <a onClick={() => { handleShow(s) }}> ❗</a>}


        </>;
      }
    },
    {
      field: 'code1c',
      headerName: 'Код 1с',
      width: 130,
      renderCell: (params) => {
        return (
          <>
            <div className='d-flex flex-column'>
              <Link to={urlRouter + '/update/' + params.row.id} style={{ textDecoration: "none", color: "rgba(0, 0, 0, 0.87)" }}>{(params.row.code1c == null) ? 'Нова накладна' : params.row.code1c}</Link>
              <span className='text-muted' style={{ fontSize: '10px' }}>{(params.row.storage != undefined) ? params.row.storage.name : '...'}</span>
              <span className='text-muted' style={{ fontSize: '8px' }}>#{params.row.id}</span>
            </div>
          </>
        );
      }
    },
    {
      field: 'dateEnteredIn1c',
      headerName: 'Дата внесення в 1C',
      type: 'date',
      width: 150,
      renderCell: (params) => {
        return params.row?.dateEnteredIn1c ? dayjs.utc(params.row.dateEnteredIn1c).format('YYYY-MM-DD HH:mm:ss') : "";
      }
    },
    {
      field: 'dateOrder',
      headerName: 'Дата Відвантаження',
      type: 'date',
      width: 100,
      renderCell: (params) => {
        return dayjs.utc(params.row.dateOrder).format('YYYY-MM-DD');
      }
    },
    {
      field: 'sumOrdered',
      headerName: 'Сума',
      width: 100,
      renderCell: (params) => {
        let convertedCurrency = useCurrency({ currencyIncome: params.row.curency });
        // var c = (params.row.curency != undefined) ? params.row.curency : 'uah';
        var c = (convertedCurrency.convertedCurrency !== "") ? convertedCurrency.convertedCurrency : "грн";
        return (params.row.sumOrdered != undefined) ? `${params.row.sumOrdered} ${c}` : '';
      }
    },
    {
      field: 'accounts',
      headerName: 'Контрагент',
      width: 200,
      renderCell: (params) => {
        return (params.row.account != undefined) ? params.row.account.name : '';
      }
    },

    {
      field: 'manager',
      headerName: 'Менеджер',
      width: 200,
      renderCell: (params) => {
        return (params.row.manager != undefined) ? `${params.row.manager.firstName} ${params.row.manager.lastName}` : '';
      }
    },
    {
      field: 'countProd',
      headerName: 'К-сть тов.',
      width: 60,
      renderCell: (params) => {
        return (params.row.orderProducts != undefined) ? params.row.orderProducts.length : '';
      }
    },


    // {

    //   field: 'dateEntered',
    //   headerName: 'Дата',
    //   type: 'date',
    //   width: 160,
    // },
    {
      field: 'shippingAddressStreet',
      headerName: 'Адреса',
      type: 'text',
      width: 160,
    },
    {
      field: 'description',
      headerName: 'Коментар',
      type: 'text',
      width: 160,
    },

    {
      field: 'dateEntered',
      headerName: 'Дата створення',
      type: 'date',
      width: 150,
      renderCell: (params) => {
        return dayjs.utc(params.row.dateEntered).format('YYYY-MM-DD HH:mm:ss');
      }
    },

    {
      field: "print",
      headerName: "Друк",
      sortable: false,
      width: 50,
      renderCell: (params) => {
        return (
          <>
            {
              (params.row.code1c != null) ?
                <Link to={`/orders/print/${params.row.id}`} target='_blank'>
                  <CIcon icon={cilPrint} customClassName="nav-icon" height={15} />
                </Link>
                :
                <></>

            }
          </>
        );
      }
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      width: 60,
      renderCell: (params) => {
        return <Link to={urlRouter + '/update/' + params.row.id}><CButton color="dark" variant="outline" title="Редагувати замовлення"><GrEdit className="nav-icon" /></CButton></Link>;
      }
    },
    {
      field: "clone",
      headerName: "Копіювати",
      sortable: false,
      renderCell: (params) => {
        return (
          <CButton color="dark" variant="outline" title="Копіювати замовлення"
            onClick={() => {
              copyOrder.setOrder(params.row);
            }}>
            <CIcon icon={cilClone} customClassName="nav-icon" height={15} />
          </CButton>);
      }
    },
    {
      field: "delete",
      headerName: "Видалити",
      sortable: false,
      renderCell: (params) => {
        return <CButton color="danger" variant="outline" title="Видалити замовлення"
          onClick={() => {
            Deleted(params.row)
          }
          }><CIcon icon={cilTrash} customClassName="nav-icon" height={20} /></CButton>;
      }
    },
  ];

  // Натиснення кнопки: "Enter" - виконати пошук; "Escape" - скинути пошук 
  const keyDown = (event) => {
    // Виконати пошук
    if (event.key === 'Enter' && !disabled) {
      event.preventDefault();
      handleFilterSearch(event);
    };
    // Скинути пошук
    if (event.key === 'Escape') {
      event.preventDefault();
      handleFilterReset(event);
    };
  };

  const [show, setShow] = useState(false);
  const [offcanvasData, setOffcanvasData] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (row) => {
    setOffcanvasData(row);
    setShow(true)
  };



  return (
    <>
      {/* Popup про успішні зміни (справа зверху) */}
      <ToastContainer />
      <OffcanvasOrder show={show} handleClose={handleClose} data={offcanvasData} />


      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CAccordion key={activeItem} activeItemKey={activeItem} flush>
              <CAccordionItem itemKey={1}>
                <CAccordionHeader><strong>Фільтр</strong></CAccordionHeader>
                <CAccordionBody>
                  <CRow className="my-3 mx-2">
                    <CCol md={9} className="me-auto">
                      <CRow>
                        <CCol md={4} className='filter-field'>
                          <CFormInput
                            placeholder="Код 1с" id="code1cFilterSearch" aria-label="Пошук" className="me-2"
                            onChange={handleCode1cFilterChange} value={code1cFilterSearch} onKeyDown={keyDown} autoComplete="off"
                          />
                        </CCol>
                        <CCol md={4} className='filter-field'>
                          <CFormInput
                            placeholder="Контрагент" id="accountsFilterSearch" aria-label="Пошук" className="me-2"
                            onChange={handleAccountsFilterChange} value={accountsFilterSearch} onKeyDown={keyDown} autoComplete="off"
                          />
                        </CCol>
                        <CCol md={4} className='filter-field'>
                          <CFormInput
                            placeholder="Менеджер" id="magerFilterSearch" aria-label="Пошук" className="me-2"
                            onChange={handleManagerFilterChange} value={managerFilterSearch} onKeyDown={keyDown} autoComplete="off"
                          />
                        </CCol>
                      </CRow>
                    </CCol>
                    <CCol md={3} style={{ textAlign: "end" }}>
                      <CButton className="me-2" type="submit" color="secondary" onClick={handleFilterSearch} disabled={disabled}>Пошук</CButton>
                      <CButton type="submit" color="secondary" onClick={handleFilterReset}>X</CButton>
                    </CCol>
                  </CRow>
                </CAccordionBody>
              </CAccordionItem>
            </CAccordion>



            <CCardHeader><GiShoppingCart className="nav-icon" />
              <strong>Замовлення</strong> <small></small>
            </CCardHeader>
            <CCardBody>
              <p className="text-medium-emphasis small">
                <Link to={`${urlRouter}/create`}>
                  <CButton color="secondary"><CIcon icon={cilPlus} customClassName="nav-icon" height={15} />Створити</CButton>
                </Link>
              </p>
              <div style={{ height: 600, width: '100%' }}>

                <DataGrid
                  rows={rows}
                  columns={columns}
                  pageSize={30}
                  rowsPerPageOptions={[30]}
                  pagination
                  rowCount={rowCountState}
                  paginationMode="server"
                  onPageChange={handlePageChange}
                />
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default Orders;
